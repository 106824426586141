import axios, { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/Residential";

export function fetchProjectsSummaryApiRequest(): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.PROJECTS_SUMMARY_URL}?type=Commercial`).then((d: AxiosResponse) => d.data);
}

export function fetchTopProjectsByCategoryApiRequest(): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.TOP_PROJECTSBY_CATEGORY_URL}?customerType=Residential`).then((d: AxiosResponse) => d.data);
}

