import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { cn } from "src/helpers";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  NavigationMenuLink,
} from "src/shadcn/components/ui";
import NavDropdown from "./NavDropdown";

type NavMenuItem = {
  title: string;
  link?: string;
  subLinks?: NavMenuItem[];
  
};
 type Props={
  open?:boolean;
  setopen?:(val:boolean)=>void
  navs: NavMenuItem[];
 }
export type INavMenu = NavMenuItem[];

const NavMenuReStructureComponent = ({ navs ,setopen,open}:Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="md:flex md:space-x-2 space-y-2 md:space-y-0 mb-4 sm:mb-0 ">
      {navs.map(({ link, title, subLinks }) => {
        if (link) {
          // console.log({ link });
          return (
            <div>
            <Button
              key={`nav-menu-tab-${title}-${link}`}
              onClick={() => {navigate("/" + link);
                if(open){
                  setopen&& setopen(!open)
              }else if(!open){
                  setopen&&setopen(!!open)
              }else return
              }}
              variant={"ghost"}
              className={cn({ " text-primary ": pathname == "/" + link })}
            >
              {title}
            </Button>
            </div>
          );
        } else if (subLinks) {
          return (
            // <DropdownMenu key={`nav-menu-tab-${title}-${link}`}>
            //   <DropdownMenuTrigger>
            //     <Button
            //       key={`nav-menu-tab-${title}-${link}`}
            //       variant={"ghost"}
            //       className={cn({
            //         " text-primary ": subLinks.some(
            //           ({ link }) => pathname == "/" + link
            //         ),
            //       })}
            //     >
            //       {title}
            //     </Button>
            //   </DropdownMenuTrigger>
            //   <DropdownMenuContent>
            //     {subLinks.map(({ title: subTitle, link: subLink }) => {
            //       return (
            //         <DropdownMenuItem
            //           className="p-0"
            //           key={`nav-menu-sub-tab-${subTitle}-${subLink}`}
            //         >
            //           <Button
            //             onClick={() => navigate("/" + subLink)}
            //             variant={"ghost"}
            //             className={cn({
            //               " text-primary ": pathname == "/" + subLink,
            //             })}
            //           >
            //             {subTitle}
            //           </Button>
            //         </DropdownMenuItem>
            //       );
            //     })}
            //   </DropdownMenuContent>
            // </DropdownMenu>

            <>
              <NavDropdown navlink={{ link, title, subLinks }} open={open} setopen={setopen}/>

            </>
          );
        }
      })}
    </div>
  );
};

export default NavMenuReStructureComponent;
