import { MapPin, MoveLeft } from 'lucide-react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, checkDecimal, COSTOMER_JOURNEY_SOLAR_IMAGE, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { getTempDataRootState } from 'src/redux/reducers/tempDataSlice'
import { RootState } from 'src/redux/store'
import { Button } from 'src/shadcn/components/ui'
import SolarPlanAccordian from './Component/CostomerJorneyAccordian.tsx/SolarPlanAccordian'
import CostomerJorneyOutcome from './Component/CostomerJorneyOutcome'
import SolarPlan from './Component/Solarplan/SolarPlan'
import SolarPowerGenaration from './Component/SolarPowerGenaration'
import { fetchQuotationProjectRequestApi } from 'src/services/requests/Projects'
import { useEffect, useState } from 'react'

const CostomerJourneyMain = () => {
  const SolarDeal = COSTOMER_JOURNEY_SOLAR_IMAGE
  const navigate = useNavigate()
  const { id } = useParams()

  const [isLoading, setisLoading] = useState<boolean>(false)
  const [projectDetails, setProjectDetails] = useState<any>(undefined)

  const { quotationDetails } = useSelector((state: RootState) => getTempDataRootState(state))

  useEffect(() => {
    if (id) {
      handleFetchProjectDetails()
    }
    return () => { }
  }, [id])

  const handleFetchProjectDetails = catchAsync(async () => {
    setisLoading(true)
    return id && await fetchQuotationProjectRequestApi(id)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setProjectDetails(result?.data)
      setisLoading(false)
    }
  }, () => { setisLoading(false) })


  return (
    <div className='w-full font-sans'>
      <div>
        <div className='flex items-center space-x-4'>
          <MoveLeft className='cursor-pointer ' onClick={() => navigate(`/${MAIN_ROUTES_CONST.welcome}`)} />
          <div className='font-bold text-2xl text-black'>{checkDecimal((projectDetails?.unitConsumptionPerDay), true)} (kW/day)</div>
        </div>
        <div className='flex items-center space-x-4'>
          <MapPin className='text-primary w-6 h-6' />
          <div className='text-xl font-normal text-black'>{projectDetails?.city}, {projectDetails?.state}</div>
        </div>

      </div>

      <SolarPowerGenaration projectDetails={projectDetails} />
      <CostomerJorneyOutcome />

      <div className='flex justify-center items-center space-x-2 text-primary font-bold text-2xl mt-4'><div>Get the Best Solar Deals with Our Partners</div>
        <img src={SolarDeal} alt='solarDeal' />
      </div>
      <SolarPlan projectDetails={projectDetails} />

      <SolarPlanAccordian />
      {/* < WaterSaved/> */}
      <div className='flex justify-center py-4'><Button variant={'outlinePrimary'} className='rounded-full w-[150px]'>Back</Button></div>
    </div>
  )
}

export default CostomerJourneyMain