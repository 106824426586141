import { BASE_URL, MOBILE_BASE_URL } from "../api_const"

export const URL_LOGIN = BASE_URL + "/login"
export const URL_REGISTRATION = BASE_URL + "/register"
export const URL_CHANGE_PASSWORD = BASE_URL + "/resetpassword"
export const URL_FORGOT = BASE_URL + "/forgetpassword"
export const URL_VERIFY_OTP = BASE_URL + "/otpvalidation"
export const URL_RESEND_OTP = BASE_URL + "/resendotp"
export const URL_FETCH_USER_PROFILE_DETAILS = BASE_URL + "/profile"
export const URL_UPDATE_USER_PROFILE_DETAILS = BASE_URL + "/profile/update"
export const URL_USER_BY_TOKEN = BASE_URL + "/getuserbytoken"
export const URL_SEND_REFERRAL_LINK = BASE_URL + "/referral-link/send"
export const URL_SEND_USER_LOCATION = BASE_URL + "/landing"
