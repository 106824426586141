import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { convertToCamelCase, optionTypes, parseFiltersDataIntoQuery } from 'src/helpers'
import InputFiled from 'src/shadcn/components/custom/InputeFiled/InputFiled'
import SelectPicker2 from 'src/shadcn/components/custom/SelectPIcker2/SelectPicker2'
import { Button, Checkbox, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, Input, Label, Separator } from 'src/shadcn/components/ui'
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import RangeSelector from 'src/shadcn/components/custom/RangeSelector'

type Props = {
    filterData: any,
    selectedFilters: any,
    onChange: (e: any) => void
    setopen?: (val: boolean) => void,
    open?: boolean
}

const NewFilterLayout = ({ filterData, selectedFilters, onChange, open, setopen }: Props) => {

    const [priceRange, setPriceRange] = useState([0, 50000000]);
    const [isInvestmentSliderVisible, setIsInvestmentSliderVisible] = useState(false);
    const [isSizeSliderVisible, setIsSizeSliderVisible] = useState(false);

    const formatCurrency = (value: any) => `₹${(value / 1000000).toFixed(1)}Cr`;
    const toggleSlider = () => {
        setIsInvestmentSliderVisible(!isInvestmentSliderVisible);
    };
    const toggleSizeSlider = () => {
        setIsSizeSliderVisible(!isSizeSliderVisible);
    };

    const [filterObj, setfilterObj] = useState<any>(selectedFilters)
    const [errorObj, setErrorObj] = useState<any>({})

    useEffect(() => {
        if (selectedFilters) {
            setfilterObj(selectedFilters)
        }
        return () => { }
    }, [selectedFilters])

    const handleFilterChange = (filterName: string, filterValue: any) => {
        let prepareState = { ...filterObj }
        prepareState = { ...prepareState, [filterName]: filterValue }
        setfilterObj(prepareState)
        let query = parseFiltersDataIntoQuery(prepareState)
        onChange(query)
    };

    console.log({ filterObj });


    const handleValidateForm = () => {

        let errors = Object.entries(filterObj).reduce((acc: any, [key, value]: [any, any]) => {
            let filterType = filterData.find((d: any) => d.dataKey == key)
            if (filterType?.type == "range") {
                if (value?.min && !value?.max) {
                    acc[key] = "Please enter max value"
                }
                else if (value?.max && !value?.min) {
                    acc[key] = "Please enter min value"
                }
                else if (Number(value?.max) && Number(value?.max) < Number(value?.min)) {
                    acc[key] = "Max value is must be greater than min value"
                }
                else if (Number(value?.min) && Number(value?.min) > Number(value?.max)) {
                    acc[key] = "Min value is must be less than max value"
                }

            }
            return acc;
        }, {});

        if (Object.values(errors).length == 0) {
            setErrorObj({})
            let query = parseFiltersDataIntoQuery(filterObj)
            onChange(query)
        }
        else setErrorObj(errors)

        if (open) {
            setopen && setopen(!open)
        } else if (!open) {
            setopen && setopen(!!open)
        } else return
    }

    const MakeResetObject = useMemo(() => {
        console.log({ filterData });

        return filterData && filterData?.reduce((acc: any, curr: any) => {
            console.log({ curr });

            if (curr?.optionsType == "multiselect" || curr?.type == "list") {
                acc[curr?.dataKey] = [""]
            }
            else if (curr?.type == "range") {
                acc[curr?.dataKey] = {
                    min: 0, max: 0
                }
            }
            else acc[curr?.dataKey] = ""
            return acc

        }, {})

    }, [filterData])


    return (
        <div className="flex items-center gap-4">
            {
                filterData.map(({ name, dataKey, type, optionsType, options, regex, min, max, steps, numberFormatted, prefix, suffix }: any, indx: number) => {
                    if (optionsType === "multiselect" && type === "list") {
                        let optionValues = options.map(((option: optionTypes) => option.value))
                        let exactSelected = filterObj[dataKey]?.filter((d: any) => optionValues.includes(d))
                        return <div key={`epc-filters-${name}-${type}`} className="flex items-center space-x-2">
                            <div className="relative">
                                <DropdownMenu>
                                    <DropdownMenuTrigger className="cursor-pointer h-[40px] text-xs border border-gray-300 rounded-md p-2 uppercase flex items-center ">
                                        {exactSelected?.length > 0 ? <div>{exactSelected[0]} {exactSelected?.length > 1 ? "+" + exactSelected?.length : ""}</div> : name}
                                        <span className="ml-2">{/* arrow icon */}▼</span>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="space-y-2 p-2 bg-white border rounded-md shadow-lg">
                                        {
                                            Array.isArray(options) && options.map((option: optionTypes, indx: any) => {
                                                return (
                                                    <DropdownMenuItem key={`epc-filters-option-${name}-${indx}`} className="flex items-center space-x-2" onClick={() => {
                                                        if (filterObj[dataKey]?.includes(option.value)) {
                                                            handleFilterChange(dataKey, filterObj[dataKey]?.filter((d: any) => d !== option.value));
                                                        } else {
                                                            handleFilterChange(dataKey, [...filterObj[dataKey], option.value]);
                                                        }
                                                    }} >
                                                        <Checkbox
                                                            name={option.value}
                                                            checked={filterObj[dataKey]?.includes(option.value)}
                                                        />
                                                        <Label htmlFor={option.value}>{convertToCamelCase(option.label)}</Label>
                                                    </DropdownMenuItem>
                                                );
                                            })
                                        }
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </div>
                        </div>
                    }


                    else if (type === "range") {
                        let min_val = filterObj[dataKey]?.min;
                        let max_val = filterObj[dataKey]?.max;
                        return <RangeSelector triggerClassName={"px-4 py-2   text-sm border rounded-md"} min={min} max={max} steps={steps} value={[min_val, max_val]} name={name} onChange={([min, max]) => handleFilterChange(dataKey, { min: min, max: max })} />

                    }
                    else if (type === "text") {
                        return <div key={`epc-filters-${name}-${type}`} className="flex items-center space-x-4 text-xs">
                            {/* <div className="text-sm font-bold text-primary uppercase">{name}</div> */}
                            <div className='flex space-x-2 '>
                                {prefix ? <div>{prefix}</div> : ""}
                                <InputFiled label='' regex={regex} placeholder='Project Id' nameKey={dataKey} type='text' value={filterObj[dataKey]} onchange={(e: any) => {
                                    handleFilterChange(dataKey, e);
                                }} />
                                {suffix ? <div>{suffix}</div> : ""}
                            </div>
                        </div>
                    }
                    else if (type === "dropdown") {
                        return <div key={`epc-filters-${name}-${type}`} className="flex items-center space-x-4 text-xs">
                            <div className="relative w-[120px]">
                                <SelectPicker2 options={options} placeholder={name} value={filterObj[dataKey]} searchable onchange={(e: any) => {
                                    handleFilterChange(dataKey, e);
                                }} />
                            </div>
                        </div>
                    }
                })
            }

            <div>
                <Button variant={"outline"} className='rounded-full w-full' onClick={() => {
                    onChange({})
                    setfilterObj(MakeResetObject)
                    setErrorObj({})
                    // if (open) {
                    //     setopen && setopen(!open)
                    // } else if (!open) {
                    //     setopen && setopen(!!open)
                    // } else return
                }} >Clear Filters</Button>
            </div>
        </div>
    )
}

export default NewFilterLayout