import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { convertToCamelCase, optionTypes, parseFiltersDataIntoQuery } from 'src/helpers'
import InputFiled from 'src/shadcn/components/custom/InputeFiled/InputFiled'
import SelectPicker2 from 'src/shadcn/components/custom/SelectPIcker2/SelectPicker2'
import { Button, Checkbox, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, Input, Label, Separator } from 'src/shadcn/components/ui'

type Props = {
    filterData: any,
    selectedFilters: any,
    onChange: (e: any) => void
    setopen?: (val: boolean) => void,
    open?: boolean
}


const FilterFormComponent = ({ filterData, selectedFilters, onChange, open, setopen }: Props) => {

    const [filterObj, setfilterObj] = useState<any>(selectedFilters)
    const [errorObj, setErrorObj] = useState<any>({})

    useEffect(() => {
        if (selectedFilters) {
            setfilterObj(selectedFilters)
        }
        return () => { }
    }, [selectedFilters])

    const handleFilterChange = (filterName: string, filterValue: any) => {
        let prepareState = { ...filterObj }
        prepareState = { ...prepareState, [filterName]: filterValue }
        setfilterObj(prepareState)
    };

    console.log({ filterObj });


    const handleValidateForm = () => {

        let errors = Object.entries(filterObj).reduce((acc: any, [key, value]: [any, any]) => {
            let filterType = filterData.find((d: any) => d.dataKey == key)
            if (filterType?.type == "range") {
                if (value?.min && !value?.max) {
                    acc[key] = "Please enter max value"
                }
                else if (value?.max && !value?.min) {
                    acc[key] = "Please enter min value"
                }
                else if (Number(value?.max) && Number(value?.max) < Number(value?.min)) {
                    acc[key] = "Max value is must be greater than min value"
                }
                else if (Number(value?.min) && Number(value?.min) > Number(value?.max)) {
                    acc[key] = "Min value is must be less than max value"
                }

            }
            return acc;
        }, {});

        if (Object.values(errors).length == 0) {
            setErrorObj({})
            let query = parseFiltersDataIntoQuery(filterObj)
            onChange(query)
        }
        else setErrorObj(errors)

        if (open) {
            setopen && setopen(!open)
        } else if (!open) {
            setopen && setopen(!!open)
        } else return
    }

    const MakeResetObject = useMemo(() => {
        console.log({ filterData });

        return filterData && filterData?.reduce((acc: any, curr: any) => {
            console.log({ curr });

            if (curr?.optionsType == "multiselect" || curr?.type == "list") {
                acc[curr?.dataKey] = [""]
            }
            else if (curr?.type == "range") {
                acc[curr?.dataKey] = {
                    min: 0, max: 0
                }
            }
            else acc[curr?.dataKey] = ""
            return acc

        }, {})

    }, [filterData])

    return (
        // <div className='border  rounded-xl  px-10'>
            
        //     <div className="space-x-4  grid grid-flow-row grid-col-5">
        //         {
        //             filterData.map(({ name, dataKey, type, optionsType, options, regex, numberFormatted }: any) => {
        //                 // Commercial & Industrial dropdown (already implemented)
        //                 if (name === "Commercial & Industrial" && optionsType === "multiselect" && type === "list") {
        //                     return <div key={`epc-filters-${name}-${type}`} className="flex items-center space-x-2">
        //                         <div className="relative">
        //                             <DropdownMenu>
        //                                 <DropdownMenuTrigger className="cursor-pointer text-sm font-bold text-primary uppercase flex items-center">
        //                                     {name}
        //                                     <span className="ml-2">{/* arrow icon */}▼</span>
        //                                 </DropdownMenuTrigger>
        //                                 <DropdownMenuContent className="space-y-2 p-2 bg-white border rounded-md shadow-lg">
        //                                     {
        //                                         Array.isArray(options) && options.map((option: optionTypes, indx: any) => {
        //                                             return (
        //                                                 <DropdownMenuItem key={`epc-filters-option-${name}-${indx}`} className="flex items-center space-x-2">
        //                                                     <Checkbox
        //                                                         checked={filterObj[dataKey]?.includes(option.value)}
        //                                                         onCheckedChange={(e) => {
        //                                                             if (!e) {
        //                                                                 handleFilterChange(dataKey, filterObj[dataKey]?.filter((d: any) => d !== option.value));
        //                                                             } else {
        //                                                                 handleFilterChange(dataKey, [...filterObj[dataKey], option.value]);
        //                                                             }
        //                                                         }}
        //                                                     />
        //                                                     <Label>{convertToCamelCase(option.label)}</Label>
        //                                                 </DropdownMenuItem>
        //                                             );
        //                                         })
        //                                     }
        //                                 </DropdownMenuContent>
        //                             </DropdownMenu>
        //                         </div>
        //                     </div>
        //                 }
        //                 // Residential & Societies dropdown (newly added)
        //                 else if (name === "Residential & Societies" && optionsType === "multiselect" && type === "list") {
        //                     return <div key={`epc-filters-${name}-${type}`} className="flex items-center space-x-2">
        //                         <div className="relative">
        //                             <DropdownMenu>
        //                                 <DropdownMenuTrigger className="cursor-pointer text-sm font-bold text-primary uppercase flex items-center">
        //                                     {name}
        //                                     <span className="ml-2">{/* arrow icon */}▼</span>
        //                                 </DropdownMenuTrigger>
        //                                 <DropdownMenuContent className="space-y-2 p-2 bg-white border rounded-md shadow-lg">
        //                                     {
        //                                         Array.isArray(options) && options.map((option: optionTypes, indx: any) => {
        //                                             return (
        //                                                 <DropdownMenuItem key={`epc-filters-option-${name}-${indx}`} className="flex items-center space-x-2">
        //                                                     <Checkbox
        //                                                         checked={filterObj[dataKey]?.includes(option.value)}
        //                                                         onCheckedChange={(e) => {
        //                                                             if (!e) {
        //                                                                 handleFilterChange(dataKey, filterObj[dataKey]?.filter((d: any) => d !== option.value));
        //                                                             } else {
        //                                                                 handleFilterChange(dataKey, [...filterObj[dataKey], option.value]);
        //                                                             }
        //                                                         }}
        //                                                     />
        //                                                     <Label>{convertToCamelCase(option.label)}</Label>
        //                                                 </DropdownMenuItem>
        //                                             );
        //                                         })
        //                                     }
        //                                 </DropdownMenuContent>
        //                             </DropdownMenu>
        //                         </div>
        //                     </div>
        //                 }
        //                 // Handle other types like range, text, number, dropdown as before (same as before)
        //                 else if (type === "range") {
        //                     let min = filterObj[dataKey]?.min;
        //                     let max = filterObj[dataKey]?.max;

        //                     return <div key={`epc-filters-${dataKey}-${type}`} className="flex items-center space-x-4">
        //                         <div className="text-sm font-bold text-primary uppercase">{name}</div>
        //                         <InputFiled label='' placeholder='min' nameKey={selectedFilters[dataKey]} type='number' numberFormatted value={min} onchange={(e: any) => {
        //                             const { max } = filterObj[dataKey];
        //                             handleFilterChange(dataKey, { min: e?.toString()?.trim(), max });
        //                         }} />
        //                         <InputFiled label='' placeholder='max' nameKey={selectedFilters[dataKey]} type='number' numberFormatted value={max} onchange={(e: any) => {
        //                             const { min } = filterObj[dataKey];
        //                             handleFilterChange(dataKey, { min, max: e?.toString()?.trim() });
        //                         }} />
        //                     </div>
        //                 }
        //                 else if (type === "text") {
        //                     return <div key={`epc-filters-${name}-${type}`} className="flex items-center space-x-4">
        //                         <div className="text-sm font-bold text-primary uppercase">{name}</div>
        //                         <InputFiled label='' regex={regex} placeholder='Enter' nameKey={dataKey} type='text' value={filterObj[dataKey]} onchange={(e: any) => {
        //                             handleFilterChange(dataKey, e);
        //                         }} />
        //                     </div>
        //                 }
        //                 else if (type === "number") {
        //                     return <div key={`epc-filters-${name}-${type}`} className="flex items-center space-x-4">
        //                         <div className="text-sm font-bold text-primary uppercase">{name}</div>
        //                         <InputFiled label='' regex={regex} placeholder='0' nameKey={dataKey} type='number' numberFormatted={numberFormatted} value={filterObj[dataKey]} options={options} onchange={(e: any) => {
        //                             handleFilterChange(dataKey, e?.toString()?.trim());
        //                         }} />
        //                     </div>
        //                 }
        //                 else if (type === "dropdown") {
        //                     return <div key={`epc-filters-${name}-${type}`} className="flex items-center space-x-4">
        //                         <div className="text-sm font-bold text-primary uppercase">{name}</div>
        //                         <div className="relative w-[200px]">
        //                             <SelectPicker2  options={options} value={filterObj[dataKey]} searchable onchange={(e: any) => {
        //                                 handleFilterChange(dataKey, e);
        //                             }} />
        //                         </div>
        //                     </div>
        //                 }
        //             })
        //         }
        //     </div>
        //     <div className='flex space-x-4 mt-4 justify-center'>
        //         <Button className='rounded-full w-[200px]' onClick={handleValidateForm}>Apply</Button>
        //         <Button variant={"outline"} className='rounded-full w-[200px]' onClick={() => {
        //             onChange({});
        //             setfilterObj(MakeResetObject);
        //             setErrorObj({});
        //             if (open) {
        //                 setopen && setopen(!open);
        //             } else if (!open) {
        //                 setopen && setopen(!!open);
        //             } else return;
        //         }}>Clear Filters</Button>
        //     </div>
        // </div>



        <>
            <div>
                {
                    filterData.map(({ name, dataKey, type, optionsType, options, regex, numberFormatted }: any) => {
                        if (optionsType == "multiselect" && type == "list") {
                            return <div key={`epc-filters-${name}-${type}`}>
                                <div className='flex justify-between items-center'>
                                    <div className='mt-2 text-sm font-bold text-primary uppercase mb-4'>{name}</div>
                                    <Checkbox checked={optionsType == "multiselect" ? options?.every((d: any) => filterObj[dataKey].includes(d.value) )  : ""} onCheckedChange={(e: any) => {
                                        if (!e) {
                                            handleFilterChange(dataKey, optionsType == "multiselect" ? [] : "")
                                        }
                                        else handleFilterChange(dataKey, optionsType == "multiselect" ? options?.map((d: any) => d.value) : null)
                                    }} />

                                </div>
                                {
                                    Array.isArray(options) && options.map((option: optionTypes, indx: any) => {
                                        const previusSelectedValues: Array<any> = filterObj[dataKey as keyof object] || []
                                        return <div key={`epc-filters-option-${name}-${indx}`} className=' flex justify-between mb-2'>
                                            <Label className=''>{convertToCamelCase(option.label)}</Label>
                                            <Checkbox checked={optionsType == "multiselect" ? filterObj[dataKey as keyof object]?.includes(option.value) : filterObj[dataKey as keyof object] == option.value} onCheckedChange={(e) => {
                                                if (!e) {
                                                    if (optionsType == "multiselect") {
                                                        handleFilterChange(dataKey, filterObj[dataKey]?.filter((d: any) => d != option.value))
                                                    }
                                                    else handleFilterChange(dataKey, "")
                                                }
                                                else {
                                                    if (optionsType == "multiselect") {
                                                        handleFilterChange(dataKey, [...filterObj[dataKey], option.value])
                                                    }
                                                    else handleFilterChange(dataKey, option.value)
                                                }

                                            }} />
                                        </div>
                                    })
                                }
                                <Separator />
                            </div>
                        }
                        else if (type == "range") {
                            // console.log({ dataKey });
                            let min = filterObj[dataKey as keyof object] && filterObj[dataKey as keyof object]?.min;
                            let max = filterObj[dataKey as keyof object] && filterObj[dataKey as keyof object]?.max;

                            return <div key={`epc-filters-${dataKey}-${type}`} >
                                <div className='mt-2 text-sm font-bold text-primary uppercase'>{name}</div>
                                <div className='flex space-x-2'>
                                    <InputFiled label='' placeholder='min' nameKey={selectedFilters[dataKey as keyof object]} type='number' numberFormatted value={min} onchange={(e: any) => {
                                        let values = e?.toString()?.trim()
                                        const { min, max } = filterObj[dataKey as keyof object];
                                        handleFilterChange(dataKey, { min: values, max })
                                    }} />
                                    <InputFiled label='' placeholder='max' nameKey={selectedFilters[dataKey as keyof object]} type='number' numberFormatted value={max} onchange={(e: any) => {
                                        let values = e?.toString()?.trim()
                                        const { min, max } = filterObj[dataKey as keyof object];
                                        handleFilterChange(dataKey, { min, max: values })
                                    }} />
                                </div>
                                {errorObj[dataKey as keyof object] ? <div className='mt-2 text-xs text-destructive'>{errorObj[dataKey as keyof object]}</div> : null}
                            </div>
                        }
                        else if (type == "text") {
                            return <div key={`epc-filters-${name}-${type}`}>
                                {/* <div>{name}</div> */}
                                <div className='flex flex-col'>
                                    <Label className='mt-2 text-sm font-bold text-primary uppercase'>{name}</Label>
                                    <InputFiled label='' regex={regex} placeholder='Enter' nameKey={dataKey} type='text' value={filterObj[dataKey as keyof object]} onchange={(e: any) => {
                                        // let values = e?.toString()?.trim()
                                        handleFilterChange(dataKey, e)
                                    }} />

                                </div>
                            </div>
                        }
                        else if (type == "number") {
                            return <div key={`epc-filters-${name}-${type}`}>
                                {/* <div>{name}</div> */}
                                <div className='flex flex-col'>
                                    <Label className='mt-2 text-sm font-bold text-primary uppercase'>{name}</Label>
                                    <InputFiled label='' regex={regex} placeholder='0' nameKey={dataKey} type='number' numberFormatted={numberFormatted} value={filterObj[dataKey as keyof object]} options={options} onchange={(e: any) => {
                                        let values = e?.toString()?.trim()
                                        handleFilterChange(dataKey, values)
                                    }} />

                                </div>
                            </div>
                        }
                        else if (type == "dropdown") {
                            return <div key={`epc-filters-${name}-${type}`}>
                                {/* <div>{name}</div> */}
                                <div className='flex flex-col'>
                                    <Label className='mt-2 text-sm font-bold text-primary uppercase'>{name}</Label>
                                    <SelectPicker2 options={options} placeholder={name} value={filterObj[dataKey as keyof object]} searchable onchange={(e: any) => {
                                        handleFilterChange(dataKey, e)
                                    }} />
                                </div>
                            </div>
                        }
                    })
                }
            </div>
            <div className='flex flex-col space-y-2 mt-4'>
                <Button className='rounded-full w-full ' onClick={handleValidateForm} >Apply</Button>
                <Button variant={"outline"} className='rounded-full w-full' onClick={() => {
                    onChange({})
                    setfilterObj(MakeResetObject)
                    setErrorObj({})
                    if(open){
                        setopen&& setopen(!open)
                    }else if(!open){
                        setopen&&setopen(!!open)
                    }else return
                }} >Clear Filters</Button></div>
        </>
    )
}

export default FilterFormComponent