import React from 'react'
import OrderConfirmationDetails from 'src/modules/OrderConfirmation/OrderConfirmationDetails'
import ReferalForm from 'src/modules/ReferAndEarn/ReferalForm'
import ReferAndEarnDetails from 'src/modules/ReferAndEarn/ReferAndEarnDetails'

type Props = {}

const OrderConfirmation = (props: Props) => {
    return (
        <div className='mb-[200px]'>
        <OrderConfirmationDetails />
        </div>

    )
}

export default OrderConfirmation