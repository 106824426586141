import { Check } from 'lucide-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IMG_CROWDFUNDING_BANNER } from 'src/helpers'
import Playbtn from 'src/shadcn/components/custom/Playbtn'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/shadcn/components/ui'

type Props = {}

const CareerPage = (props: Props) => {

  const [showSection, setShowSection] = useState<any>("section-1")

  return (
    <div>
      <div className='w-full h-[500px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_CROWDFUNDING_BANNER + ")", backgroundSize: "cover" }} >
      <div className=' flex flex-col justify-center items-center md:items-stretch h-[100%] space-y-8'>
          <div className='flex  md:justify-start   md:ml-24 w-[70vw]  bg-primary'>
            <div className='p-4  text-white text-xl md:text-2xl font-bold text-center' >Together, let's create a sustainable future! </div>
          </div>
          <div className='flex justify-center md:justify-end '>
            <div className=' space-y-2 bg-white p-4 w-[70vw]'>
              <div className='font-bold'>What does it mean to work at Orange Current?</div>
              <div>
                <div className='flex items-center space-x-2 '>
                  <span><Check size={16} /></span><span>A Purposeful role that makes a genuine impact.</span>
                </div>
                <div className='flex items-center space-x-2 '>
                  <span><Check size={16} /></span><span>Collaborative, yet dynamic working atmosphere.</span>
                </div>
                <div className='flex items-center space-x-2 '>
                  <span><Check size={16} /></span><span>Clear responsibilities with promising career path.</span>
                </div>
                <div className='flex items-center space-x-2 '>
                  <span><Check size={16} /></span><span>Open work culture, promoting plenty of room for your own ideas</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='my-[50px]'>
      <div className='text-3xl md:text-4xl text-center'>
          A <span className='font-bold'>team</span> that works <span className='font-bold'>together</span> out of <br /><span className='font-bold'>passion</span> and with <span className='font-bold'>joy</span>
        </div>
      </div>
      <div className='w-[80vw] mx-auto'>
        Our passionate team is committed to driving the global energy transition. If you share this vision and are eager to thrive in a dynamic startup environment, we’d love to have you on board!
 
        We're hiring for the following positions.
      </div>
      <div className=' w-[80vw] mx-auto my-[50px]'>
        <Accordion type="single" value={showSection} collapsible>
          <AccordionItem className=' ' value="section-1">
            <AccordionTrigger className='border-b-2 border-dotted border-black '>
              <div className='flex items-center space-x-2'>
                <span><Playbtn /></span>
                <span className='font-bold uppercase text-lg'>Head: Project Sales (Solar)</span>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className='space-y-4 my-4'>

                <div className='text-primary font-bold'>Apply now as a Head: Project Sales (Solar) Renewable Energy</div>
                <div>At Orange Current Technologies, we’re making sustainable investments in renewable energy accessible to everyone. Our platform serves as a <span className='font-bold'>“hub for independent investors and commercial solar plant operators”, providing a “digital home” for their projects and investments</span>.</div>
                <div>To strengthen our team and help communicate our services to customers across India, we are looking for passionate individuals to join us. Apply now!</div>
                <div>
                  <div className='font-bold text-md'>Project Sales - Solar:</div>
                  <div className='p-4'>
                    <ul className='list-disc space-y-2'>
                      <li>Manage a network of project stakeholders like Solar Project developers, Land/Rooftop Owners, Investors, C&I customers to drive the sale of solar projects at various stages.</li>
                      <li>Establish and nurture relationships with key stakeholders in the renewable energy industry, including investors, government bodies, and energy utilities.</li>
                      <li>Expand the company’s presence in new markets and industries through effective channel partnerships and project sales strategies.</li>
                      <li>Identify and secure new business opportunities from solar project developers across commercial, industrial, and institutional sectors.</li>
                      <li>Manage the entire project sales cycle from lead generation, negotiations to closing the deals.</li>
                      <li>Conduct due diligence of the project to provide accurate project information to prospective C&I buyers, including regulatory requirements and quality standards.</li>
                      <li>Assist buyers in closing deals by providing technical, financial, and strategic support throughout the proposal and negotiation phases.</li>
                      <li>Resolve any issues or concerns faced by clients during the project lifecycle, ensuring timely and satisfactory solutions.</li>
                      <li>Ensure a high level of customer satisfaction by delivering on promises, maintaining clear communication, and offering continued support during and after project implementation.</li>
                      <li>Foster long-term relationships with clients and encourage repeat business and referrals.</li>
                      <li>Work closely with IT platform development teams to customize available solar services that meet the specific needs of clients.</li>
                      <li>Identify trends, market opportunities, and competitor activities to stay ahead in the renewable energy space.</li>
                      <li>Track sales progress and KPIs related to project sales and channel partner performance.</li>
                      <li>Analyse sales data to optimize strategies and improve overall sales performance.</li>
                      <li>Provide regular updates and sales reports to senior management, ensuring alignment with overall business goals.</li>
                      
                    </ul>
                  </div>
                </div>
                <div>
                  <div className='font-bold text-md'>Qualifications:</div>
                  <div className='p-4'>
                    <ul className='list-disc space-y-2'>
                      <li>Bachelor’s degree in Business, Engineering, Renewable Energy, or a related field.</li>
                      <li>Proven experience in sales or business development in the renewable energy sector (solar experience preferred).</li>
                      <li>Strong understanding of renewable energy technologies, especially large-scale solar projects, and the project sales cycle.</li>
                      <li>Excellent communication, negotiation, and presentation skills.</li>
                      <li>Ability to build and manage relationships with channel partners, contractors, and clients.</li>
                      <li>Ability to oversee complex sales and project implementation processes.</li>
                      <li>Self-driven, goal-oriented, and able to work independently in a fast-paced, results-focused environment.</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div className='font-bold text-md'>What We Offer:</div>
                  <div className='p-4'>
                    <ul className='list-disc space-y-2'>
                      <li>Work in one of the most exciting and fastest-growing markets around sustainability and follow the daily developments and trends in the Solar industry.</li>
                      <li>Opportunity to implement your own ideas as part of India’s first online platform for solar project transactions.</li>
                      <li>Fast decision-making processes through flat hierarchies and direct reporting to the CEO.</li>
                      <li>In addition to your fixed salary, you will receive an attractive and performance-related bonus.</li>
                      <li>Access to a robust portfolio of renewable energy projects and solutions.</li>
                      <li>A dynamic and supportive startup environment where innovation and growth are encouraged.</li>
                      <li>Competitive commission-based structure with performance incentives.</li>
                      <li>You will experience a good work-life balance with 30 days of holiday per year.</li>
                      <li>Cordial working atmosphere in an exciting environment, committed to a common vision.</li>
                    </ul>
                  </div>
                </div>
                {/* <div>If you are passionate about renewable energy projects and have a track record in sales and project development, apply now to <span className='text-primary'>join Orange Current Technologies as a CTO-Solar  Project Sales</span></div> */}
                <div>
                  Explore our open positions or impress us with a spontaneous application! Send your documents, including your earliest possible start date, to <Link className='text-blue-600 underline underline-offset-2 cursor-pointer' target="_blank" to={`mailto:${"career@orangecurrent.com"}`}>career@orangecurrent.com</Link>. At our core, we value diversity, and your application will be assessed solely based on your professional qualifications. Have questions? Don’t hesitate to reach out to us via email anytime.
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
}

export default CareerPage