import React from "react";
import { ISolarPlanArr, SolarPlanArr } from "./Component/modules";
import SolarplanCard from "./Component/SolarplanCard";
import { useSelector } from "react-redux";
import { getTempDataRootState } from "src/redux/reducers/tempDataSlice";

const SolarPlan = ({ projectDetails }: { projectDetails: any }) => {

  // const { quotationDetails } = useSelector((state: any) => getTempDataRootState(state))

  return (
    <div className="font-sans mt-2">
      <div className="text-[#000000B2] font-bold text-[20px]">
        Select the Solar Plan that Powers your Growth.
      </div>
      <div className="text-[16px] font-normal text-[#191D2380]">
        Read all plans or contact us we will guide you better.
      </div>

      <div className="w-full grid grid-cols-3 mt-8 gap-12">
        {SolarPlanArr.map(
          (
            {
              benefits,
              emiPrice,
              name,
              overAllPrice,
              projectPrice,
              unlockPrice,
              features,
              typicalRate,
              interestSavings,
              id
            },
            index: number
          ) => {
            let emi = (parseFloat(projectDetails?.interestAmount)).toFixed(2)



            projectPrice = projectDetails?.projectCost
            overAllPrice = projectDetails?.totalProjectCost || 0

            if (name == "STANDARD") {
              projectPrice = parseFloat(projectDetails?.projectCost) + 10000
              overAllPrice = parseFloat(projectDetails?.totalProjectCost) + 10000 || 0
              emi = (projectPrice * parseFloat((projectDetails?.interestRate)?.replace("%", ""))).toFixed(2)
            }
            else if (name == "premium") {
              projectPrice = parseFloat(projectDetails?.projectCost) + 20000
              overAllPrice = parseFloat(projectDetails?.totalProjectCost) + 20000 || 0
              emi = (projectPrice * parseFloat((projectDetails?.interestRate)?.replace("%", ""))).toFixed(2)
            }

            emiPrice = {
              discountPrice: emi,
              totalPrice: emi
            }

            return (
              <SolarplanCard
                benefits={benefits}
                emiPrice={emiPrice}
                name={name}
                overAllPrice={overAllPrice}
                projectPrice={projectPrice}
                unlockPrice={unlockPrice}
                features={features}
                typicalRate={typicalRate}
                interestSavings={interestSavings}
                key={name}
                id={id}
                projectDetails={projectDetails}
              />
            )
          }
        )}
      </div>
    </div>
  );
};

export default SolarPlan;
