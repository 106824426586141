import { Link } from "react-router-dom";
import { CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_PROJECT_RIGHTS1, CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_ROOFTOP_AREA, CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_TURNKEY_OPEN_FIELD, EXHISTING_PROJECT_SERVICE_IMAGE, FINANCE_SERVICE_IMAGE, FormField, GROUND_MOUNTED_SERVICE_IMAGE, LEASE_LAND_SERVICE_IMAGE, NEW_CONSTRUCTED_SERVICE_IMAGE, NUMBER_REGEX, OandM_SERVICE_IMAGE, P2P_IMAGE, PRODUCT_SERVICE_IMAGE, PROJECTS_RIGHTS_SERVICE_IMAGE, ROOFTOP_SERVICE_SOLAR_IMAGE, SOLAR_EPC_SERVICE_IMAGE } from "src/helpers";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import * as Yup from "yup" 
interface IWhyWeDOArr {
  image: any;
  title: string;
  link: string;
  btnName: string;
  description: string;
}


const Lease_out_Land = LEASE_LAND_SERVICE_IMAGE
const Rooftop_Solar = ROOFTOP_SERVICE_SOLAR_IMAGE
const Ground_Mounted = GROUND_MOUNTED_SERVICE_IMAGE
const Project_Rights = PROJECTS_RIGHTS_SERVICE_IMAGE;
const New_Constructed_Project = NEW_CONSTRUCTED_SERVICE_IMAGE
const Sale_of_Existing_Project = EXHISTING_PROJECT_SERVICE_IMAGE
const Finance = FINANCE_SERVICE_IMAGE
const Solar_EPC = SOLAR_EPC_SERVICE_IMAGE
const Solar_O = OandM_SERVICE_IMAGE
const P2P_Trading = P2P_IMAGE
const Products = PRODUCT_SERVICE_IMAGE

export const RESIDENTIAL_SERVICES: IWhyWeDOArr[] = [

  {
    btnName: "Learn More",
    description:
      "Transform unused rooftop space into a clean energy</br> powerhouse with our customized solar solutions,</br> perfect for homes and businesses. ",
    image: Rooftop_Solar,
    link: MAIN_ROUTES_CONST.roof_tops,
    title: "Rooftop Solar",
  },
  {
    btnName: "Learn More",
    description:
      "Efficient, scalable solar energy systems tailored </br>to large land parcels, delivering maximum</br> performance and sustainability.",
    image: Ground_Mounted,
    link: "",
    title: "Ground Mounted",
  },

  {
    btnName: "Learn More",
    description:
      "Flexible financing options to bring your solar</br> projects to life, ensuring you have the resources to</br> meet energy goals.",
    image: Finance,
    link: "",
    title: "Finance ",
  },
  {
    btnName: "Learn More",
    description:
      "Comprehensive Engineering, Procurement, and</br> Construction (EPC) services for end-to-end solar</br> project execution",
    image: Solar_EPC,
    link: "",
    title: "Solar EPC ",
  },
]

export const COMMERCIAL_SERVICES: IWhyWeDOArr[] = [
  {
    btnName: "Learn More",
    description:
     "Acquire full or partial project rights as per the need from the utility scale project or solar parks.",
    image: Project_Rights,
    link: MAIN_ROUTES_CONST.project_rights,
    title: "Buy Project Rights",
  },
  {
    btnName: "Learn More",
    description:
      "Discover the perfect solar project for you, whether it's a rooftop installation or a fully commissioned solar projects.",
    image: Sale_of_Existing_Project,
    link: MAIN_ROUTES_CONST.sell_existing_projects,
    title: "Buy existing projects",
  },
  {
    btnName: "Learn More",
    description:
     "Explore ongoing construction projects (rooftop installations or solar parks) from whereas developers.",
    image: New_Constructed_Project,
    link: MAIN_ROUTES_CONST.new_constructed_projects,
    title: "Buy New Construction Projects",
  },
  {
    btnName: "Learn More",
    description:
      "Get rooftop space that fits your project requirements on lease or list your own rooftop to attract interested leases.",
    image: Lease_out_Land,
    link: MAIN_ROUTES_CONST.lands,
    title: "Lease Roof Areas",
  },
  {
    btnName: "Learn More",
    description:
      "Offer your land for lease or discover the perfect open space for a new solar project.",
    image: Lease_out_Land,
    link: MAIN_ROUTES_CONST.lands,
    title: "Offer Land for utility scale project",
  },


  // {
  //   btnName: "Learn More",
  //   description:
  //     "Explore profitable solar projects ready for sale,</br> offering a seamless transition into renewable,/br> energy investment.",
  //   image: Sale_of_Existing_Project,
  //   link: MAIN_ROUTES_CONST.sell_existing_projects,
  //   title: "Sale of Existing Project ",
  // },
  // {
  //   btnName: "Learn More",
  //   description:
  //     "Ensure optimal performance and longevity of your</br> solar systems with expert operations and</br> maintenance services.",
  //   image: Solar_O,
  //   link: "",
  //   title: "Solar O&M ",
  // },
  // {
  //   btnName: "Learn More",
  //   description:
  //     "Seamlessly trade energy through peer-to-peer</br> platforms, promoting efficiency and sustainability.",
  //   image: P2P_Trading,
  //   link: "",
  //   title: "P2P Trading ",
  // },
  // {
  //   btnName: "Learn More",
  //   description:
  //     "Explore high-quality solar products from leading </br>manufacturers, designed to meet your energy</br> needs.",
  //   image: Products,
  //   link: MAIN_ROUTES_CONST.solarkit,
  //   title: "Products ",
  // },
]

export const SolarServicesDetails: IWhyWeDOArr[] = [
  {
    btnName: "Learn More",
    description:
      "Unlock prime land opportunities for your solar</br> installations with flexible lease options designed</br> to maximize energy potential.",
    image: Lease_out_Land,
    link: MAIN_ROUTES_CONST.lands,
    title: "Land Lease Rent",
  },
  {
    btnName: "Learn More",
    description:
      "Transform unused rooftop space into a clean energy</br> powerhouse with our customized solar solutions,</br> perfect for homes and businesses. ",
    image: Rooftop_Solar,
    link: MAIN_ROUTES_CONST.roof_tops,
    title: "Rooftop Solar",
  },
  {
    btnName: "Learn More",
    description:
      "Efficient, scalable solar energy systems tailored </br>to large land parcels, delivering maximum</br> performance and sustainability.",
    image: Ground_Mounted,
    link: "",
    title: "Ground Mounted",
  },
  {
    btnName: "Learn More",
    description:
      "Acquire full ownership or development rights</br> to cutting-edge solar projects that offer long-</br>term growth and returns.",
    image: Project_Rights,
    link: MAIN_ROUTES_CONST.project_rights,
    title: "Project Rights",
  },
  {
    btnName: "Learn More",
    description:
      "Invest in newly constructed solar installations,</br> providing immediate access to clean energy>/br> and financial benefits.",
    image: New_Constructed_Project,
    link: MAIN_ROUTES_CONST.new_constructed_projects,
    title: "New Constructed Project ",
  },
  {
    btnName: "Learn More",
    description:
      "Explore profitable solar projects ready for sale,</br> offering a seamless transition into renewable,/br> energy investment.",
    image: Sale_of_Existing_Project,
    link: MAIN_ROUTES_CONST.sell_existing_projects,
    title: "Sale of Existing Project ",
  },
  {
    btnName: "Learn More",
    description:
      "Flexible financing options to bring your solar</br> projects to life, ensuring you have the resources to</br> meet energy goals.",
    image: Finance,
    link: "",
    title: "Finance ",
  },
  {
    btnName: "Learn More",
    description:
      "Comprehensive Engineering, Procurement, and</br> Construction (EPC) services for end-to-end solar</br> project execution",
    image: Solar_EPC,
    link: "",
    title: "Solar EPC ",
  },
  {
    btnName: "Learn More",
    description:
      "Ensure optimal performance and longevity of your</br> solar systems with expert operations and</br> maintenance services.",
    image: Solar_O,
    link: "",
    title: "Solar O&M ",
  },
  // {
  //   btnName: "Learn More",
  //   description:
  //     "Seamlessly trade energy through peer-to-peer</br> platforms, promoting efficiency and sustainability.",
  //   image: P2P_Trading,
  //   link: "",
  //   title: "P2P Trading ",
  // },
  // {
  //   btnName: "Learn More",
  //   description:
  //     "Explore high-quality solar products from leading </br>manufacturers, designed to meet your energy</br> needs.",
  //   image: Products,
  //   link: MAIN_ROUTES_CONST.solarkit,
  //   title: "Products ",
  // },
];


export const COMMERCIAL_SERVICES_DATA_NEW = [
  {
    "heading": "BUY PROJECT RIGHTS",
    "number": "01",
    "description":"Acquire full or partial project rights as per the need from the utility scale project or solar parks.",
    "link": MAIN_ROUTES_CONST.project_rights_services
  },
  {
    "heading": "BUY EXISTING PROJECTS",
    "number": "02",
    "description":"Discover the perfect solar project for you, whether it's a rooftop installation or a fully commissioned solar projects.",
    "link": MAIN_ROUTES_CONST.sales_existing_project_service
  },
  {
    "heading": "BUY NEW CONSTRUCTION PROJECTS",
    "number": "03",
    "description":  "Explore ongoing construction projects (rooftop installations or solar parks) from various developers.",
    "link": MAIN_ROUTES_CONST.new_constructed_project_service
  },
  {
    "heading": "LEASE ROOF AREAS",
    "number": "04",
    "description": "Get rooftop space that fits your project requirements on lease or list your own rooftop to attract interested lessees.",
    "link": MAIN_ROUTES_CONST.rooftop_solar_services
  },
  {
    "heading": "OFFER LAND FOR UTILITY SCALE PROJECT",
    "number": "05",
    "description": "Offer your land for lease or discover the perfect open space for a new solar park.",
    "link": MAIN_ROUTES_CONST.land_lease_services
  }
]

export const FAQ_Data = [
  {
    question: "How does the sale work at this project marketplace?",
    answer: <div>The best way to understand how this marketplace works, please get in touch with us through <Link className="text-primary tex-md underline underline-offset-2" to={"/" + MAIN_ROUTES_CONST.contact_us} >contact us</Link> and our team will reach out to you.</div>
  },
  {
    question: "From what size are systems and spaces sold on this marketplace?",
    answer: "For economic viability, the project size above 100 kWp, free land above 500 Sq meter, rooftop above 200 Sq meter can be listed on this marketplace."
  },
  {
    question: "What should be considered when selling?",
    answer: "Your chance of selling the project increases by providing a) sharing COMPLETE documentation and b) quick completion of the due diligence."
  },
  {
    question: "What documents are required for the sale?",
    answer: <div>
      <div>For a swift sale, we recommend providing the following documents:</div><br />
      <div>
        <ul className='' >
          <li className='space-x-1'><span className='text-lg font-bold'>•</span><span>The latest photos of your system</span></li>
          <li className='space-x-1'><span className='text-lg font-bold'>•</span><span>System Commissioning Reports</span></li>
          <li className='space-x-1'><span className='text-lg font-bold'>•</span><span>Disco bills for the last 3 years</span></li>
          <li className='space-x-1'><span className='text-lg font-bold'>•</span><span>Lease contract</span></li>
        </ul>
      </div>
    </div>
  }
]

export const REFERENCES_ANALYSIS = [
  {
    count: 100,
    name: "Listed Project",
    dataKey:"totalProjects",
    annotation: "+"
  },
  {
    count: 4,
    dataKey:"uniqueStates",
    name: "No. of States",
    annotation: ""
  },
  {
    count: 12,
    dataKey:"SuccessfulTransaction",
    name: "Successful Transaction",
    annotation: "+"
  }
]

export const REFERENCES_PRODUCTS = [
  {
    img: CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_ROOFTOP_AREA,
    name: "RoofTop Area Open Field",
    data: "0.7",
    units: "MWp"
  },
  {
    img: CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_TURNKEY_OPEN_FIELD,
    name: "Turnkey Open Field",
    data: "4.7",
    units: "MWp"
  },
  {
    img: CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_PROJECT_RIGHTS1,
    name: "Project rights open field",
    data: "100",
    units: "MWp"
  },
  {
    img: CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_PROJECT_RIGHTS1,
    name: "Project rights open field",
    data: "40",
    units: "MWp"
  },
]


export const REFERENCES_TOP_PRODUCTS = [
  {
    img: CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_ROOFTOP_AREA,
    name: "RoofTop Area Open Field",
    data: "1.06",
    units: "MWp"
  },
  {
    img: CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_TURNKEY_OPEN_FIELD,
    name: "Turnkey Open Field",
    data: "4.7",
    units: "MWp"
  },
  {
    img: CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_PROJECT_RIGHTS1,
    name: "Project rights open field",
    data: "100",
    units: "MWp"
  },
  {
    img: CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_PROJECT_RIGHTS1,
    name: "Project rights open field",
    data: "50",
    units: "MWp"
  },
  {
    img: CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_PROJECT_RIGHTS1,
    name: "Project rights open field",
    data: "40",
    units: "MWp"
  },
]



export interface ICustomerDetailsForm {
  phoneNumber: string,
}
export const CUSTOMER_DETAILS_FIELDS: FormField[] = [

 
  {
      label: 'Phone Number',
      dataKey: 'phoneNumber',
      componentType: 'input',
      componentProps: {
          type: 'text',
          required: true,
          regex: NUMBER_REGEX,
          placeholder: 'Enter your phone number',
          maxLength: 10
      },
  },

];

export const CUSTOMER_DETAILS_INIT: ICustomerDetailsForm = {
  phoneNumber: '',
};

export const CustomerDetailsFormSchema = Yup.object().shape({
  phoneNumber: Yup.string().length(10,"Must be 10 digits").required('Phone number is required'),
});