import React from 'react'
import { Button } from 'src/shadcn/components/ui'
import { Sheet, SheetClose, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from 'src/shadcn/components/ui/sheet'
import { boolean } from 'yup'
interface MarketplacesheetProps{
    children:any
    setopen:(val:boolean)=>void,
    open:boolean
}
const Marketplacesheet = ({children,open,setopen}:MarketplacesheetProps) => {

  return (
    <div>
        <Sheet onOpenChange={(val:boolean)=>setopen(val)} open={open}>
      <SheetTrigger asChild>
        <Button variant="outline">Filter</Button>
      </SheetTrigger>
      <SheetContent className='p2' side={'left'}>
{children}
      </SheetContent>
    </Sheet>
    </div>
  )
}

export default Marketplacesheet