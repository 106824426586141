import React, { useEffect, useState } from 'react'
import MapFrame from './MapFrame/MapFrame'
import { Button } from 'src/shadcn/components/ui'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getTempDataRootState, resetMapData } from 'src/redux/reducers/tempDataSlice'
import { checkDecimal } from 'src/helpers'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'
import { useNavigate, useParams } from 'react-router-dom'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { useDispatch } from 'react-redux'

type Props = {}

const AreaCalculate = (props: Props) => {


    const [getMapData, setgetMapData] = useState<any>(undefined)
    const { mapData } = useSelector((state: RootState) => getTempDataRootState(state))
    const [showConfirmBox, setshowConfirmBox] = useState<boolean>(false)
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetMapData())
        return () => { }
    }, [])


    return (
        <div className='w-[90vw] mx-auto space-x-4 pb-[100px]'>
            <div className='font-bold text-primary text-xl uppercase py-4'>
                Area Calculator
            </div>
            <div className='space-y-4'>
                <div className='w-[90vw] h-[500px] relative'>
                    <MapFrame />
                </div>
                <div className='flex  '>
                    <div className='flex flex-col space-y-2 text-2xl'>
                        <div className='font-bold text-gray-400'>Panel/Area Report</div>
                        <div className='flex flex-col space-y-8 text-2xl'>
                            <div>
                                <div className='font-semibold'>Area</div>
                                <div>{mapData?.area?.polygon ? checkDecimal(mapData?.area?.polygon, true) : 0} Sq Meter</div>
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                <div>
                                    <div className='font-semibold'>Total Panels Area</div>
                                    <div>{mapData?.area?.panels ? checkDecimal(mapData?.area?.panels, true) : 0} Sq. Meter</div>
                                </div>
                                <div>
                                    <div className='font-semibold'>Total Panels Count</div>
                                    <div>{mapData?.panels?.length ? checkDecimal(mapData?.panels?.length, true) : 0}</div>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                <div>
                                    <div className='font-semibold'>Total Obstacles Area</div>
                                    <div>{mapData?.area?.obstacles ? checkDecimal(mapData?.area?.obstacles, true) : 0} Sq. Meter</div>
                                </div>
                                <div>
                                    <div className='font-semibold'>Total Obstacles Count</div>
                                    <div>{mapData?.obstacles?.length ? checkDecimal(mapData?.obstacles?.length, true) : 0}</div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className='mt-8 flex justify-center space-x-2'>
                {mapData && <Button className='w-[150px]' onClick={() => {
                    if (!mapData) {
                        setshowConfirmBox(true)
                    }
                    else navigate("/" + MAIN_ROUTES_CONST.customerJourney + "/" + id)
                }} >Continue</Button>}
                <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.customerJourney + "/" + id)} className='w-[150px]' variant={'outlinePrimary'}>Skip</Button>
            </div>
            <CustomeDialog isOpen={showConfirmBox} headerTitle={"Confirm"} onCancle={() => { setshowConfirmBox(false) }} onSubmit={() => navigate("/" + MAIN_ROUTES_CONST.customerJourney + "/" + id)}   >
                <p className='mb-4'>You not select area do you want to continue?.</p>
            </CustomeDialog>
        </div>
    )
}

export default AreaCalculate