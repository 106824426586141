import { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/Wallet";


export function fetchWalletByUserIDApiRequest(user_id: string): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_FETCH_WALLET_BALANCE_BY_USER_ID}/${user_id}`).then((d: AxiosResponse) => d.data);
}


