import { VISION_ICON } from 'src/helpers'

type Props = {}

const Vision = (props: Props) => {
    return (
        <div className=''>
        <div className=' grid grid-cols-1 md:grid-cols-4 gap-4' >
            <div className='h-[100px] w-[100px] md:h-[120px] md:w-[120px] mx-auto bg-gray-200 flex justify-center items-center rounded-full'>
                    <img src={VISION_ICON} style={{  height: "70px" }} />
                </div>
                <div className='md:col-span-3 flex flex-col items-center md:items-start space-y-2 text-center'>
                    <div className='font-bold text-xl ' >Vision</div>
                    <div className='w-[300px] md:w-full text-center md:text-start'>To spearhead India's energy transition as the foremost platform for renewable energy projects and solutions, empowering individuals and communities to actively engage in and benefit from clean energy, while advancing global sustainability through innovation, financial empowerment, and a steadfast commitment to the environment</div>
                </div>
            </div>
        </div>
    )
}

export default Vision