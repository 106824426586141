import React from 'react'
import { TrendingUp } from "lucide-react"
import { CartesianGrid, Line, LineChart, XAxis } from "recharts"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
    TabsList,
    TabsTrigger,
} from "src/shadcn/components/ui"
import { Tabs } from '@radix-ui/react-tabs'

const chartData = [
   
]

const chartConfig = {
    desktop: {
        label: "Desktop",
        color: "hsl(var(--chart-1))",
    },
    mobile: {
        label: "Mobile",
        color: "hsl(var(--chart-2))",
      },

} satisfies ChartConfig

type Props = {}



const OffTakerAnalysis = (props: Props) => {
    return (

        <Card className='shadow-none '>
            <CardHeader>
                <div className='md:flex justify-between items-end mb-2'>
                    <div className=''>
                        <CardTitle className='whitespace-nowrap'>Electricity consumption and generation</CardTitle>
                        <CardDescription>January - June 2024</CardDescription>
                    </div>
                    <div className='flex md:justify-end justify-center'>
                        <div>
                            <Tabs defaultValue="weekly" >
                                <TabsList>
                                    <TabsTrigger className='text-xs' value="weekly">Weekly</TabsTrigger>
                                    <TabsTrigger className='text-xs' value="monthly">Monthly</TabsTrigger>
                                    <TabsTrigger className='text-xs' value="yearly">Yearly</TabsTrigger>
                                </TabsList>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </CardHeader>

            <CardContent>
                <div className='h-[300px]'>
                    <ChartContainer config={chartConfig} className='max-h-full w-full'>
                        <LineChart
                            accessibilityLayer
                            data={[]}
                            margin={{
                                left: 12,
                                right: 12,
                            }}
                        >
                            <CartesianGrid vertical={false} />
                            <XAxis
                                dataKey="month"
                                tickLine={false}
                                axisLine={false}
                                tickMargin={8}
                                tickFormatter={(value) => value.slice(0, 3)}
                            />
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent hideLabel />}
                            />
                            <Line
                                dataKey="desktop"
                                type="monotone"
                                stroke="var(--color-desktop)"
                                strokeWidth={2}
                                dot={false}
                            />
                            <Line
                                dataKey="mobile"
                                type="monotone"
                                stroke="var(--color-mobile)"
                                strokeWidth={2}
                                dot={false}
                            />
                          
                        </LineChart>
                    </ChartContainer>
                </div>

            </CardContent>

            <CardFooter className="flex-col items-start gap-2 text-sm">

            </CardFooter>
        </Card>

    )
}

export default OffTakerAnalysis