import { FormField } from "src/helpers";
import CustomTextIcon from "src/shadcn/components/custom/CustomeSymbols/CustomTextIcon";
import * as yup from "yup"

export interface subscribeForm {
    email: string
}

export const INIT_SUBSCRIBE_FORM: subscribeForm = {
    email: ""
}

export const SUBSCRIBE_FORM_SCHEMA = yup.object().shape({
    email: yup.string()
        .email('Invalid email format')
        .required('Email is required'),
});


export const SUBSCRIBE_FORM_FIELD: FormField[] = [
    {
        dataKey:"email",
        label:"",
        componentType:"input",
        componentProps:{
            type:'email',
            placeholder:"Email"
            
        }
    }
]