import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MapCapture from '../MapCapture/MapCapture';
import InputFiled from 'src/shadcn/components/custom/InputeFiled/InputFiled';
import { Button, Input } from 'src/shadcn/components/ui';

const MapFrame = () => {
  const mapRef = useRef(null);
  const searchBoxRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [marker, setMarker] = useState(null);
  const [location, setLocation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD-DdI-LfalDIB2xAhjIdGNBLwtldQAEeM&libraries=drawing,places,geometry`;
        script.async = true;
        script.onload = initializeMap;
        script.onerror = () => console.error('Failed to load Google Maps script.');
        document.head.appendChild(script);
      } else {
        initializeMap();
      }
    };

    const initializeMap = () => {
      if (!window.google || mapInstance) return;

      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 19.076, lng: 72.8777 },
        zoom: 13,
        mapTypeControl: false,
        fullscreenControl: false,
        mapTypeId: window.google.maps.MapTypeId.SATELLITE,
      });

      const searchBox = new window.google.maps.places.SearchBox(searchBoxRef.current);
      map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(searchBoxRef.current);

      map.addListener('bounds_changed', () => {
        searchBox.setBounds(map.getBounds());
      });

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();
        if (!places || places.length === 0) return;

        const place = places[0];
        const location = place.geometry?.location;
        if (!location) return;

        if (!marker) {
          const newMarker = new window.google.maps.Marker({
            position: location,
            map: map,
          });
          setMarker(newMarker);
        } else {
          marker.setPosition(location);
        }

        map.setCenter(location);
        map.setZoom(15);
      });

      setMapInstance(map);
    };

    loadGoogleMapsScript();

    return () => {
      // Cleanup Google Maps objects
      if (marker) {
        marker.setMap(null);
        setMarker(null);
      }
    };
  }, [mapInstance, marker]);

  const handleCapture = () => {
    if (!mapInstance || !mapRef.current) return;

    const frame = document.querySelector('.frame-overlay').getBoundingClientRect();
    const mapBounds = mapRef.current.getBoundingClientRect();

    const zoom = mapInstance.getZoom();
    const latLngBounds = {
      north: mapInstance.getCenter().lat() + (frame.top - mapBounds.top) / mapBounds.height,
      south: mapInstance.getCenter().lat() - (mapBounds.bottom - frame.bottom) / mapBounds.height,
      east: mapInstance.getCenter().lng() + (frame.right - mapBounds.right) / mapBounds.width,
      west: mapInstance.getCenter().lng() - (mapBounds.left - frame.left) / mapBounds.width,
    };

    setLocation({ bounds: latLngBounds, zoom });
  };

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
      {!location ? (
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
          {/* Map container */}
          <div ref={mapRef} style={{ height: '100%', width: '100%' }} />

          {/* Search bar */}
          <Input
            ref={searchBoxRef}
            type="text"
            placeholder="Search location"
            className='absolute top-0 left-0 right-0 h-10 bg-white border-2 border-black'
          />

          {/* Frame overlay */}
          <div
            className="frame-overlay"
            style={{
              position: 'absolute',
              border: '1px solid white',
              background: 'rgba(255, 255, 125, 0.2)',
              width: '50%',
              height: '70%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              pointerEvents: 'none',
            }}
          />

          {/* Capture button */}
          <div className='absolute left-0 right-0 top-10 bg-slate-900 w-full p-4 flex justify-center'>
            <Button onClick={handleCapture}>
              Confirm Location
            </Button>
          </div>
        </div>
      ) : (
        <MapCapture location={location} />
      )}
    </div>
  );
};

export default MapFrame;
