import axios, { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/CommercialAndIndustrial";


export function fetchTop5ProjectsApiRequest(): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.TOP_5_PROJECTS_URL}`).then((d: AxiosResponse) => d.data);
}


export function fetchProjectsSummaryApiRequest(): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.PROJECTS_SUMMARY_URL}`).then((d: AxiosResponse) => d.data);
}

export function fetchTopProjectsByCategoryApiRequest(): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.TOP_PROJECTSBY_CATEGORY_URL}`).then((d: AxiosResponse) => d.data);
}

