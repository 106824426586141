import React from 'react'
import { IMG_CONTACT_US_BANNER, IMG_FAQ_BANNER } from 'src/helpers'

type Props = {}

const PrivacyPage = (props: Props) => {
  return (
    <div className='mb-32'>
      <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_CONTACT_US_BANNER + ")", backgroundSize: "cover" }} >
        <div className='flex flex-col justify-center items-center h-[100%]'>
          <div className='text-primary text-4xl ' >Data Privacy</div>
        </div>
        </div>
       <div className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto'>
       <p className="mb-4">
        This privacy policy explains the type, scope, and purpose of the processing of personal
        data (hereinafter referred to as "data") within our online offering and the websites,
        functions, and content associated with it, as well as external online presences, such as
        our social media profiles (hereinafter jointly referred to as "online offering"). With
        regard to the terms used, such as "processing" or "person responsible", we refer to the
        definitions in Art. 4 of the General Data Protection Regulation (GDPR).
      </p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Person Responsible</h2>
        <p>Orange Current Technologies Pvt Ltd</p>
        <p>Email: <a href="mailto:sales@orangecurrent.com" className="text-blue-500">sales@orangecurrent.com</a></p>
        <p>Managing Directors: Vijay Dhonde</p>
        <p>
          Link to imprint:{" "}
          <a href="http://www.electreecity.in" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            www.electreecity.in
          </a>
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Types of Data Processed</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>Inventory data (e.g., names, addresses).</li>
          <li>Contact details (e.g., email, telephone numbers).</li>
          <li>Content data (e.g., text entries, documents, photographs, videos).</li>
          <li>Usage data (e.g., websites visited, interest in content, access times).</li>
          <li>Meta/communication data (e.g., device information, IP addresses).</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Categories of Data Subjects</h2>
        <p>
          Visitors and users of the online offer (hereinafter we refer to the data subjects
          collectively as “users”).
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Purpose of Processing</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>Provision of the online offer, its functions and content.</li>
          <li>Answering contact requests and communicating with users.</li>
          <li>Security measures.</li>
          <li>Reach measurement/marketing.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Terms Used</h2>
        <p className="mb-2">
          <strong>“Personal data”</strong> is all information that relates to an identified or
          identifiable natural person (hereinafter “data subject”); A natural person is considered
          identifiable if he or she can be identified, directly or indirectly, in particular by
          reference to an identifier such as a name, an identification number, location data, an
          online identifier (e.g. cookie), or to one or more special features that express the
          physical, physiological, genetic, mental, economic, cultural, or social identity of that
          natural person.
        </p>
        <p className="mb-2">
          <strong>"Processing"</strong> is any operation or set of operations carried out with or
          without the aid of automated procedures in connection with personal data. The term is
          broad and covers practically every handling of data.
        </p>
        <p className="mb-2">
          <strong>"Pseudonymisation"</strong> the processing of personal data in such a way that the
          personal data can no longer be attributed to a specific data subject without the use of
          additional information, provided that this additional information is kept separately and
          is subject to technical and organisational measures to ensure that the personal data is
          not assigned to an identified or identifiable natural person.
        </p>
        <p className="mb-2">
          <strong>"Profiling"</strong> means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person’s performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements.
        </p>
        <p className="mb-2">
          The <strong>"controller"</strong> is the natural or legal person, public authority, agency or other body which, alone or jointly with others, decides on the purposes and means of processing personal data.
        </p>

        <p className="mb-2">
          <strong>"Processor"</strong> is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Relevant Legal Bases</h2>
        <p>
          In accordance with Art. 13 GDPR, we will inform you of the legal bases for our data
          processing. If the legal basis is not mentioned in the data protection declaration, the
          following applies: The legal basis for obtaining consent is Art. 6 Para. 1 lit. a and Art.
          7 GDPR, the legal basis for processing to fulfill our services and carry out contractual
          measures as well as answering inquiries is Art. 6 Para. 1 lit. b GDPR, the legal basis for
          processing to fulfill our legal obligations is Art. 6 Para. 1 lit. c GDPR, and the legal
          basis for processing to protect our legitimate interests is Art. 6 Para. 1 lit. f GDPR. In
          the event that vital interests of the data subject or another natural person require the
          processing of personal data, Art. 6 Para. 1 lit. d GDPR serves as the legal basis.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Security Measures</h2>
        <p>
          We take security measures in accordance with Art. 32 GDPR, taking into account the state
          of the art, the implementation costs and the nature, scope, circumstances and purposes of
          the processing as well as the risks to the rights and freedoms of natural persons.
        </p>
      </section>
      </div>


    </div>
  )
}

export default PrivacyPage