import Autoplay from "embla-carousel-autoplay";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCusrousal } from "src/modules/Welcome/useCusrousal";
import { getTempDataRootState, resetSetBannerIndex, setSelectedBannerIndex } from "src/redux/reducers/tempDataSlice";
import { RootState } from "src/redux/store";
import {
    Carousel,
    CarouselApi,
    CarouselContent
} from "src/shadcn/components/ui";
import ServiceTile from "./ServiceTile";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { cn, feadIn } from "src/helpers";
import { DATA } from "./modules";
import { motion, useInView, useScroll } from "framer-motion";



type Props = {}

const CommercialServicesCarousal = (props: Props) => {
    const navigation = useNavigate();

    const [setApi, setSetApi] = useState<CarouselApi>();
    const [current, setCurrent] = React.useState(0);
    const dispatch = useDispatch()
    const { selectedIndex, scrollSnaps, onDotButtonClick } = useCusrousal(setApi);
    const plugin = React.useRef(
        Autoplay({ delay: 4000, stopOnInteraction: true })
    );
    const ref = useRef<any>(null)

    const isInView = useInView(ref, {})

    const [canScrollNext, setCanScrollNext] = useState(false);
    const [canScrollPrev, setCanScrollPrev] = useState(false);
  
    const updateScrollButtons = useCallback(() => {
      if (!setApi) return;
      setCanScrollNext(setApi.canScrollNext());
      setCanScrollPrev(setApi.canScrollPrev());
    }, [setApi]);

    useEffect(() => {
        console.log({ isInView });
        return () => { }
    }, [isInView])


    const handleMouseEnter = () => {
        plugin.current.stop();
    };

    const handleMouseLeave = () => {
        plugin.current.play();
    };

    const handleNext = () => {
        if (setApi) {
            setApi.scrollNext();
        }
    };


    const handlePrev = () => {
        if (setApi) {
            setApi.scrollPrev();
        }
    };

    // useEffect(() => {
    //     if (!setApi) {
    //         return;
    //     }
    //     setCurrent(setApi.selectedScrollSnap() + 1);
    //     setApi.on("select", () => {
    //         setCurrent(setApi.selectedScrollSnap() + 1);
    //     });
    // }, [setApi]);

    useEffect(() => {
        if (!setApi) return;
        updateScrollButtons();
        setApi.on("select", updateScrollButtons);
        setApi.on("reInit", updateScrollButtons);
      }, [setApi, updateScrollButtons]);



    console.log(selectedIndex,"index");



    return (
        <div className="w-full relative">
            <Carousel

                setApi={setSetApi}
                plugins={[plugin.current]}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="w-full overflow-hidden md:mx-[45px] " 
            >

                {/* <motion.div className="card"
                    initial="hidden" whileInView="show" viewport={{ amount: 0.5 }} variants={feadIn("up", 0.1)}  > */}
                    <CarouselContent ref={ref} className="mx-[55px] md:mx-[45px] ">

                        {DATA.map((d: any) => {
                            return <ServiceTile {...d} />

                        })}
                    </CarouselContent>
                {/* </motion.div> */}


                {canScrollPrev &&
                     (<button onClick={handlePrev}>
                        <MdOutlineKeyboardArrowLeft size={64}
                            className={cn(
                                "hover:bg-white hover:text-primary hover:p-1 transition duration-300 ease-in-out rounded-full",
                                "absolute cursor-pointer rounded-full hover:text-primary",
                                "md:left-3 left-1 top-[40%] -translate-y-[40%]"
                            )} />
                    </button>)}
                 {canScrollNext && ( <button className=" " onClick={handleNext}>
                        <MdOutlineKeyboardArrowRight size={64}
                            className={cn(
                                "hover:bg-white hover:text-primary hover:p-1 transition duration-300 ease-in-out rounded-full",
                                "absolute cursor-pointer rounded-full hover:text-primary",
                                "right-1  top-[40%] -translate-y-[40%]"
                            )} />
                    </button> )}
            </Carousel>
        </div>
    );
}

export default CommercialServicesCarousal