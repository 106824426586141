import { COSTOMER_JOURNEY_OUTCOME_ANNUAL_ELECTRICITY_IMAGE, COSTOMER_JOURNEY_OUTCOME_LIFETIME_PRODUCTION_IMAGE, COSTOMER_JOURNEY_OUTCOME_LITTER_OF_WATER_IMAGE, COSTOMER_JOURNEY_OUTCOME_SOLAR_IMAGE, COSTOMER_JOURNEY_OUTCOME_TONS_OF_CO2_IMAGE, COSTOMER_JOURNEY_OUTCOME_TREES_SEEDING_IMAGE } from 'src/helpers'

const CostomerJorneyOutcome = () => {
    const costomerJourneyOutcome= COSTOMER_JOURNEY_OUTCOME_SOLAR_IMAGE
const litterOfWater = COSTOMER_JOURNEY_OUTCOME_LITTER_OF_WATER_IMAGE;
const tonesOfCo2 = COSTOMER_JOURNEY_OUTCOME_TONS_OF_CO2_IMAGE;
const treeSeeding = COSTOMER_JOURNEY_OUTCOME_TREES_SEEDING_IMAGE
const lifetimeProduction= COSTOMER_JOURNEY_OUTCOME_LIFETIME_PRODUCTION_IMAGE
const peplesAnnualElectricity= COSTOMER_JOURNEY_OUTCOME_ANNUAL_ELECTRICITY_IMAGE
  return (
    <div className='w-full font-sans mt-12 h-[480px] rounded-[50px] p-10 text-white text-lg font-bold' style={{
        background: `url(${costomerJourneyOutcome})`,
        backgroundSize: "cover",
      }}>
        <div className=' flex flex-col justify-center items-center space-y-2'>
            <div>
                <div className='text-center'>350,196 LTR</div>
                <p className='text-center'>Liters of water saved by avoiding consuption<br/> of coal-fired electricity.</p>
            </div>
            <div>
                <img alt='' src={litterOfWater} className='w-[100px] h-[100px]'/>
            </div>
        </div>

        <div className='grid grid-cols-2 -mt-[4%]'>
        <div className=' flex  justify-start items-center space-x-4'>
            <div>
                <div className='text-center'>1,516.2 T</div>
                <p className='text-center'>Tones of Co2 over Lifetime.</p>
            </div>
            <div>
                <img alt='' src={tonesOfCo2} className='w-[100px] h-[100px]'/>
            </div>
        </div>

        <div className=' flex flex-row-reverse justify-start items-center '>
            <div className='ml-4'>
                <div className='text-center'>38896</div>
                <p className='text-center'>Trees Seeding grown for 10 Years.</p>
            </div>
            <div>
                <img alt='' src={treeSeeding} className='w-[100px] h-[100px]'/>
            </div>
        </div>
        </div>

        <div className='grid grid-cols-2 mt-[5%]'>
        <div className=' flex flex-row-reverse justify-end items-center space-x-4'>
            <div className='ml-4'>
                <div className='text-center'>1,750,000 kWp</div>
                <p className='text-center'>Lifetime Production over 25 years.</p>
            </div>
            <div>
                <img alt='' src={lifetimeProduction} className='w-[100px] h-[100px]'/>
            </div>
        </div>

        <div className=' flex  justify-end items-center space-x-4 '>
            <div className=''>
                <div className='text-center'>1,465.5 KWp</div>
                <p className='text-center'>People’s Annual Electricity</p>
            </div>
            <div>
                <img alt='' src={peplesAnnualElectricity} className='w-[100px] h-[100px]'/>
            </div>
        </div>
        </div>
    </div>
  )
}

export default CostomerJorneyOutcome