import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  ENQUIRY_FORM_FIELDS,
  ENQUIRY_INIT,
  EnquiryFormSchema,
} from "./modules";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import RoundedButton from "src/shadcn/components/custom/RoundedButton";
import { useDispatch } from "react-redux";
import { catchAsync, cn, IS_SUCCESS_RESPONSE, TYPE_OF_SPACE_ENUM } from "src/helpers";
import { createQuotationRequestRequestApi } from "src/services/requests/QuotationRequests";
import { toast } from "sonner";
import { setQuotationdetails } from "src/redux/reducers/tempDataSlice";
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog";
import { Button } from "src/shadcn/components/ui";
import { useNavigate } from "react-router-dom";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { createQuotationProjectRequestApi } from "src/services/requests/Projects";

const EnquiryForm = ({ customerType }: { customerType: any }) => {

  const [isLoading, setisLoading] = useState(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [fetchingLatLong, setfetchingLatLong] = useState<boolean>(false)
  const [showLoactionDailog, setShowLoactionDailog] = useState<boolean>(false)
  const [projectDetails, setProjectDetails] = useState<any>(undefined)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    getMyLocation()
    return () => {
    }
  }, [])


  const formik = useFormik({
    initialValues: ENQUIRY_INIT,
    validationSchema: EnquiryFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission here
      // handleRegistrationForm()
      // getMyLocation()
      if (!formik.values?.lat && !formik.values?.long) {
        setShowLoactionDailog(true)
      }
      else handleSubmitForm()
    },
  });


  useEffect(() => {
    if (customerType) {
      formik.setFieldValue("customerType", customerType)
    }

    return () => { }
  }, [customerType])



  const getMyLocation = () => {
    setfetchingLatLong(true);
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setfetchingLatLong(false);
          formik.setFieldValue("lat", position.coords.latitude);
          formik.setFieldValue("long", position.coords.longitude);
          // handleSubmitForm({ lat: position.coords.latitude, long: position.coords.longitude })
        },
        (error) => {
          setfetchingLatLong(false);
          // if (error.code === error.PERMISSION_DENIED) {
          //   toast.error("Location access denied. Please enable location services in your browser.");
          // } else {
          //   toast.error("Unable to retrieve your location.");
          // }
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
      setfetchingLatLong(false);
    }
  };


  const handleSubmitForm = catchAsync(async () => {
    setisLoading(true)
    return await createQuotationRequestRequestApi({ ...formik.values, area: formik.values.area ? formik.values.area + "SqFt" : "" })
  }, (result: any) => {
    if (result.status == 201) {
      console.log({ result_data: result.data });

      toast.success("Request submited..")
      dispatch(setQuotationdetails({ ...formik.values, ...result.data }))
      // document.getElementById("quotation-details")?.scrollIntoView({
      //   behavior: "smooth",
      //   block: "center"
      // });

      let data = result.data

      let postData = {
        "freeAreaAvailableOnRoofTop": "500 SqFt",
        "freeAreaAvailableOnGround": "0",
        "typeOfSpace": formik.values.typeOfSpace,
        "monthlyBillAmount": formik.values.monthlyBillAmount,
        "unitRequiredPerMonth": data?.unitPerMonth,
        "contactPersonNumber": formik.values.phoneNumber,
        "pinCode": formik.values.pincode,
        "customerType": formik.values.customerType,
      }

      if (formik.values.typeOfSpace == TYPE_OF_SPACE_ENUM.Land) {
        postData = {
          ...postData,
          "freeAreaAvailableOnGround": formik.values.area + " SqFt",
        }
      }
      else if (formik.values.typeOfSpace == TYPE_OF_SPACE_ENUM.Rooftop) {
        postData = {
          ...postData,
          "freeAreaAvailableOnRoofTop": formik.values.area + " SqFt",
        }
      }

      setProjectDetails(postData)

      console.log({ createProjectPostData: postData });
      handleCreateProject(postData)
      // getMyLocation()
      // navigate("/" + MAIN_ROUTES_CONST.customerJourney)
    }
  }, () => { setisLoading(false) })


  const handleCreateProject = catchAsync(async (postData: any) => {
    return await createQuotationProjectRequestApi(postData)
  }, (result: any, data: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      // setShowDialog(true)
      console.log({ result, data });
      if (data?.args[0]) {
        setProjectDetails({
          ...data?.args[0],
          _id: result._id
        })
        dispatch(setQuotationdetails({ ...formik.values, ...projectDetails, _id: result._id }))
      }
      formik.resetForm()
      navigate("/" + MAIN_ROUTES_CONST.area_calculator + "/" + result._id)
      setisLoading(false)
    }
  }, () => { setisLoading(false) })


  console.log({ values: formik.values, errors: formik.errors, projectDetails })

  return (
    <div className="">
      <div className="space-y-2">
        <form className="space-y-2" onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 p-4">
            {ENQUIRY_FORM_FIELDS.map((field: any) => {
              return (
                <RenderFormComponents
                  key={`login-form-${field.dataKey}`}
                  formik={formik}
                  {...field}
                  value={formik.values[field.dataKey as keyof object]}
                  onChange={(e: any) => formik.setFieldValue(field.dataKey, e)}
                />
              );
            })}

          </div>
          <div className="mt-4 flex justify-center">
            <Button
              variant={'outlinePrimary'}
              isLoading={false}
              disabled={isLoading}
              type="submit"
              className={cn(" bg-orange-100 hover:text-black rounded-md", { "bg-black/10 border-none text-white hover:bg-white ": isLoading })}
            >
              {isLoading ? "Loading..." : "Submit Your Request"}
            </Button>
          </div>
        </form>
      </div>
      <CustomeDialog isOpen={showDialog} headerTitle={"Request Submited"} onCancle={() => setShowDialog(false)} submitBtnTitle="Skip & Continue" showExtraBtn extrabtnTitle="Calculate Area" onSubmit={() => {
        if (projectDetails?._id) navigate("/" + MAIN_ROUTES_CONST.customerJourney + "/" + projectDetails?._id)
      }} extraBtnClick={() => { if (projectDetails?._id) navigate("/" + MAIN_ROUTES_CONST.area_calculator + "/" + projectDetails?._id) }} >
        <p className='pb-4'>Thank You for showing your interest, our team will contact you soon.</p>

      </CustomeDialog>
      <CustomeDialog isOpen={showLoactionDailog} headerTitle={"Allow Location"} hideDefaultCancle onSubmit={() => {
        setShowLoactionDailog(false);
        // window.location.reload()
      }} >
        <p className='pb-4'>Please allow browser location </p>
      </CustomeDialog>
    </div>
  );
};

export default EnquiryForm;
