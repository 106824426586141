import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { USER_ROLES } from "src/helpers";
import { useAuth } from "src/modules/Auth/Core";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { getTempDataRootState } from "src/redux/reducers/tempDataSlice";
import { RootState } from "src/redux/store";
import { INavMenu } from "src/shadcn/components/custom/NavMenuComponent";
import NavMenuReStructureComponent from "src/shadcn/components/custom/NavMenuReStructureComponent";
interface NavigationMenu_RProps {
  open?: boolean;
  setopen?: (val: boolean) => void
}
const NavigationMenu_R = ({ setopen, open }: NavigationMenu_RProps) => {

  const { currentUser } = useAuth()
  const { userLocationDetails } = useSelector((state: RootState) => getTempDataRootState(state))
  const DEFAULT_NAV: INavMenu = [
    {
      title: "Home",
      link: MAIN_ROUTES_CONST.welcome,
    },
    {
      title: "Dashboard",
      link: MAIN_ROUTES_CONST.dashboard,
    },
    {
      title: "Quotation Details",
      link: MAIN_ROUTES_CONST.quotation_details,
    },
    {
      title: "Services",
      link: "",
      subLinks: [
        {
          title: "Commercial & Industrial",
          link: MAIN_ROUTES_CONST.commercial_services,
        },
        {
          title: "Residential",
          link: MAIN_ROUTES_CONST.residential_services,
        },
        {
          title: "Finance Services",
          link: MAIN_ROUTES_CONST.finance_service,
        },
      ],

    },
    {
      title: "Explore Market",
      link: MAIN_ROUTES_CONST.marketplace,
    },
    {
      title: "Solutions",
      link: "",
    },
    {
      title: "About Us",
      link: "",
      subLinks: [
        {
          title: "About US",
          link: MAIN_ROUTES_CONST.about_us,
        },
        {
          title: "Vision & Mission",
          link: MAIN_ROUTES_CONST.mission_and_vision,
        },
        {
          title: "Career",
          link: MAIN_ROUTES_CONST.careers,
        },
        {
          title: "Our Team",
          link: MAIN_ROUTES_CONST.our_team,
        },
        {
          title: "Contact Us",
          link: MAIN_ROUTES_CONST.contact_us,
        },
      ],
    },
    {
      title: "Investment Calculator",
      link: MAIN_ROUTES_CONST.investment_calculator,
    },
    {
      title: "Enquiry Request",
      link: MAIN_ROUTES_CONST.new_enquiry_request,
    },
  ];

  const NAVIGATOIN_MENU_ITEMS = useMemo(() => {
    let routes = DEFAULT_NAV
    console.log(currentUser?.role,'role');
    

    routes = DEFAULT_NAV.filter(({ title }: any) => !["Dashboard", "Investment Calculator", "Enquiry Request", "Quotation Details"].includes(title))
    if (currentUser?.role == USER_ROLES.INVESTOR) {
      routes = DEFAULT_NAV.filter(({ title }: any) => !["Home", "Enquiry Request"].includes(title))
    }
    else if (currentUser?.role == USER_ROLES.OFF_TAKER) {
      routes = DEFAULT_NAV.filter(({ title }: any) => !["Home", "Investment Calculator"].includes(title))
    }


    if (userLocationDetails?.address) {
      let spliteAddr = userLocationDetails?.address.split(",").reverse();
      console.log({ spliteAddr });

      if (!spliteAddr.some((d: any) => d.toLowerCase().includes("maharashtra") || d.toLowerCase().includes("uttar pradesh"))) {
        routes = routes.filter(({ title }: any) => title != "Explore Market")
      }
    }
    return routes

  }, [currentUser, DEFAULT_NAV, userLocationDetails])

  return (
    <div>
      <NavMenuReStructureComponent navs={NAVIGATOIN_MENU_ITEMS} open={open} setopen={setopen} />
    </div>
  );
};

export default NavigationMenu_R;
