import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { cn } from 'src/helpers';

type NavMenuItem = {
    title: string;
    link?: string;
    subLinks?: NavMenuItem[];
};

type Props = {
    navlink: NavMenuItem,
    open?:boolean;
    setopen?:(val:boolean)=>void
}

const NavDropdown = ({ navlink,open,setopen }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const { pathname } = useLocation();

    return (
        <li
            className="relative flex items-center space-x-1 ml-4" 
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
        >
            <span
                className={cn("text-sm font-medium text-slate-800 hover:text-slate-900", {
                    " text-primary ": navlink.subLinks && navlink.subLinks.some(
                        ({ link }: any) => pathname == "/" + link
                    )
                })}
                aria-expanded={isOpen}
            >
                {navlink.title}
            </span>
            <button
                className="shrink-0 p-1"
                aria-expanded={isOpen}
                onClick={(e) => {
                    e.preventDefault();
                    setIsOpen((prev) => !prev);
                }}
            >
                <span className="sr-only">Show submenu for "Flyout Menu"</span>
                <svg className="w-3 h-3 fill-slate-500" xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                    <path d="M10 2.586L11.414 4 6 9.414.586 4 2 2.586l4 4z" />
                </svg>
            </button>
            {/* Flyout Dropdown Menu */}
            {
                isOpen && (
                    <ul className="origin-top-right absolute top-full left-1/2 -translate-x-1/2 min-w-[240px] bg-white border border-slate-200 p-2 rounded-lg shadow-xl">
                        {
                            navlink.subLinks?.map((d: any) => {
                                return <li onClick={()=>{if(open){
                                    setopen&& setopen(!open)
                                }else if(!open){
                                    setopen&&setopen(!!open)
                                }else return}}>
                                    <Link to={"/" + d?.link} ><div className={cn('text-sm font-medium p-2 hover:bg-gray-100 ', {
                                        " text-primary ": pathname == "/" + d.link,
                                    })}
                                    
                                    >{d.title}</div></Link>
                                </li>
                            })
                        }

                    </ul>
                )
            }
        </li >
    );
};

export default NavDropdown