import { useAuth } from "src/modules/Auth/Core"
import InvestorDashboard from "./InvestorDashboard"
import OffTakerDashboard from "./OffTakerDashboard"
import { useNavigate } from "react-router-dom"
import MainRoutes from "src/navigations"
import { MAIN_ROUTES_CONST } from "src/navigations/modules"
import { useEffect } from "react"
import { USER_ROLES } from "src/helpers"

type Props = {}



const DashboardMain = (props: Props) => {

    const { currentUser } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        if(!currentUser) navigate("/" + MAIN_ROUTES_CONST.welcome)
        return () => { }
    }, [])


    return (
        <div className="p-[24px] w-full">
            {currentUser?.role == USER_ROLES.INVESTOR ? <InvestorDashboard /> : null}
            {currentUser?.role == USER_ROLES.OFF_TAKER ? <OffTakerDashboard /> : null}
        </div>

    )
}

export default DashboardMain