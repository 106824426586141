import axios, { AxiosResponse } from "axios";
import { getAuth } from "src/modules/Auth/Core";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/Order";
import { ICreateOrderRequestBody } from "./modules";
import { BADGE_STATUS_VARIANT, ORDER_STATUS_ENUM } from "src/helpers";


export function fetchOrdersByUserIDApiRequest(user_id: string): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_FETCH_ORDERS_BY_USER_ID}?user_id=${user_id}`).then((d: AxiosResponse) => d.data);
}

export function fetchOrderDetailsByOrderIDApiRequest(order_id: string): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_ORDER_DETAILS}/${order_id}`).then((d: AxiosResponse) => d.data);
}

export function fetchOrderSummaryByUserIDApiRequest(query: string): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_ORDER_SUMMARY_BY_USER_ID}?${query}`).then((d: AxiosResponse) => d.data);
}


export function cancelOrderApiRequest(order_id: string): Promise<AxiosResponse> {
    return axiosInstance.put(`${API.URL_CANCEL_ORDER}/${order_id}`, { "orderStatus": ORDER_STATUS_ENUM.CANCELLED }).then((d: AxiosResponse) => d.data);
}


export function createOrderApiRequest(data: any): Promise<AxiosResponse> {
    return axiosInstance.post(`${API.URL_CREATE_ORDER}`, { ...data }).then((d: AxiosResponse) => d.data);
}

export function updateOrderStatusApiRequest(id: string, data: any): Promise<AxiosResponse> {
    return axiosInstance.put(`${API.URL_UPDATE_ORDER_STATUS}/${id}`, { ...data }).then((d: AxiosResponse) => d.data);
}


export function getOrderdDetailsByOrderIdApiRequest(id: string): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.ORDER_BASE_URL}/${id}`).then((d: AxiosResponse) => d.data);
}


export function getAllCouponsApiRequest(id: string): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_COUPONS}/${id}`).then((d: AxiosResponse) => d.data);
}


export function checkCouponsStatusApiRequest(data: any): Promise<AxiosResponse> {
    return axiosInstance.post(`${API.URL_COUPONS_VALIDATE}`, data).then((d: AxiosResponse) => d.data);
}
