import { useSelector } from "react-redux"
import PaymentForm from "../Orders/PaymentForm"
import ReferalForm from "../ReferAndEarn/ReferalForm"
import AddressForm from "./AddressForm"
import OrderPaymentForm from "./OrderPaymentForm"
import { RootState } from "src/redux/store"
import { getTempDataRootState } from "src/redux/reducers/tempDataSlice"
import { catchAsync, checkDecimal, IS_SUCCESS_RESPONSE } from "src/helpers"
import HtmlCodeRender from "src/shadcn/components/custom/HtmlCodeRender"
import { Button, Table, TableBody, TableCell, TableHeader, TableRow } from "src/shadcn/components/ui"
import { createOrderApiRequest } from "src/services/requests/Order"
import { useState } from "react"
import { useAuth } from "../Auth/Core"
import { useNavigate } from "react-router-dom"
import { MAIN_ROUTES_CONST } from "src/navigations/modules"
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog"


type Props = {}

const OrderConfirmationDetails = (props: Props) => {

    const { selectedService } = useSelector((state: RootState) => getTempDataRootState(state))
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const { currentUser } = useAuth()
    const navigate = useNavigate()

    if (!selectedService?.id || !selectedService?._id) {
        navigate("/")
    }

    console.log({ selectedService });

    const handleCreateOrder = catchAsync(async () => {
        setIsLoading(true)
        return await createOrderApiRequest({
            "user_id": currentUser?.id,
            "order_total": "1",
            "total_price": selectedService?.projectPrice,
            "service_id": selectedService?.id,
            "project_id":selectedService?._id
        })
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setIsLoading(false)
            setShowDialog(true)
            // navigate("/" + MAIN_ROUTES_CONST.payment + "/" + result?.order_id)
        }
    }, () => { setIsLoading(false) })


    return (
        <div className=' h-full w-full justify-center items-center space-y-4'>
            <div className="flex flex-col space-y-8 mt-[200px] max-w-[30vw] mx-auto ">
                <div >
                    <div className='text-center font-bold text-2xl text-primary'>Order Confirmation</div>
                </div>
                <div>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Order Type</TableCell>
                                <TableCell className="text-end"><span className="font-semibold ">Service</span></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Product ID (#)</TableCell>
                                <TableCell className="text-end"><span className="font-semibold  uppercase">asdasdasdd</span></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Project Amount</TableCell>
                                <TableCell className="text-end"><span className="font-semibold ">₹ {selectedService?.overAllPrice} </span></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>EmI Duration</TableCell>
                                <TableCell className="text-end"><span className="font-semibold ">12 Months</span></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>EMI Amounts</TableCell>
                                <TableCell className="text-end"><span className="font-semibold ">₹ {selectedService?.emiPrice?.totalPrice} </span></TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell>Final Price</TableCell>
                                <TableCell><span className="font-semibold">₹ {selectedService?.overAllPrice} </span></TableCell>
                            </TableRow> */}
                        </TableBody>
                    </Table>
                    <div className="flex justify-center">
                        <Button isLoading={isLoading} onClick={() => handleCreateOrder()} >Confirm Order</Button>
                    </div>
                </div>
            </div>
            <CustomeDialog isOpen={showDialog} headerTitle="Request Generated" hideDefaultCancle onSubmit={() => { navigate("/") }} >
                <p className="mb-4" >Order Request initiated, our team will contact you soon.</p>
            </CustomeDialog>
        </div>
    )
}

export default OrderConfirmationDetails