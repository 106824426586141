import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import ProjectLeftDetails from 'src/modules/Cell/ProjectLeftDetails'
import ProjectRightDetail from 'src/modules/Cell/ProjectRightDetail'
import { resetInvestment } from 'src/redux/reducers/projectDetailsSlice'

type Props = {}

const ProductDetails = (props: Props) => {

    const { id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetInvestment())
        return () => { }
    }, [])


    return (
        <>
            {/* <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + toAbsoluteUrl("/media/jpg/marketExplorerBanner.png") + ")", backgroundSize: "cover" }} >
                <div className='flex flex-col justify-center items-center h-[100%] space-y-2'>
                    <div className='text-white font-bold text-3xl ' >
                        <div>Discover, Compare, and</div>
                        <div>Choose Solar Excellence</div>
                    </div>
                    <div className='text-white text-center ' >Explore a wide range of solar products and services <br /> tailored to meet your energy needs.</div>
                </div>
            </div> */}
            {
                id && <div className='flex '>
                    <div className='md:w-1/3 xl:w-2/6 sticky top-[3em] h-[500px] p-2 '>
                        <ProjectLeftDetails />
                    </div>
                    <div className='md:w-2/3 xl:w-4/6 w-full h-full p-2'>
                        <ProjectRightDetail />
                    </div>
                </div>
            }
        </>
    )
}

export default ProductDetails