import React, { useRef } from 'react'
import { IMG_FAQ_BANNER } from 'src/helpers'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'src/shadcn/components/ui/collapsible'
import { Plus } from 'lucide-react';
//@ts-ignore
import parse  from 'html-react-parser';

type Props = {}


const FAQ_DATA = [
    {
        "header": "What are the benefits for consumers, producers and solar power plant owners?",
        "content": `
        <p>The Orange Current market offers countless opportunities and benefits for electricity consumers, electricity producers as well as self-sufficient prosumers (those who produce and consume their own energy):</p>
<b>Consumer benefits include:</b><br/>
<ul>
<li>The option to make direct agreements with producers for a lower electricity price</li>
<li>Access and use of renewable energy without having to invest in production infrastructure such as a solar power plant</li>
<li>An easy to use platform</li>
<li>Regular monitoring of consumption through advanced applications</li>
</ul>

<b>Producer benefits include:</b><br/>
<ul>
<li>Possibility of direct agreement with the consumer enabling them to sell their surplus at higher prices</li>
<li>The opportunity to consume their own electricity regardless of the location of the power plant</li>
<li>Greater flexibility in selling electricity and creating your own circle of customers</li>
<li>Automated monthly invoicing and inflow to their bank account</li>
<li>Updated production overview through our monitoring platform feature</li>
</ul>
<b>Benefits for self-sufficient prosumers:</b><br/>
<ul>
<li>Real-time access to their production and consumption data</li>
<li>Independent decision-making on the distribution of surplus electricity at the end of the year</li>
</ul>`
    },
    {
        "header": "Why the platform is using blockchain technology?",
        "content": ""
    },
    {
        "header": "Why is there a need for OCT Tokens?",
        "content": ""
    },
    {
        "header": "What is the point of the minimum balance?",
        "content": ""
    },
    {
        "header": "What if I later decide that peer to peer energy trading is not for me?",
        "content": ""
    },
    {
        "header": "How can I make deals in the marketplace?",
        "content": ""
    },
    {
        "header": "Must I do the P2P trading by myself or is there another option?",
        "content": ""
    },
    {
        "header": "How can I sign up for this Energy Management Service?",
        "content": ""
    },
    {
        "header": "Will I be able to set different prices based on time of day or week?",
        "content": ""
    }
]

const FaqMain = (props: Props) => {


    return (
        <div>
            <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_FAQ_BANNER + ")", backgroundSize: "cover" }} >
                <div className='flex flex-col justify-center items-center h-[100%]'>
                    <div className='text-primary text-4xl ' >FAQs</div>
                    <div className='text-white text-xs ' >Frequently asked questions</div>
                </div>
            </div>
            <div className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto'>
                <div>
                    Have questions for the team?  We’ve got answers! Browse our different tabs to learn how to get started, how to use the platform and to find answers to general questions.
                </div>
                <div className=' w-100'>
                    {/* <Collapsible> */}
                    {FAQ_DATA.map(({ header, content }, indx: number) => {
                        return <Collapsible className='w-100 my-2' key={`tab-${indx}`} >
                            <CollapsibleTrigger className='w-100 border p-4 w-[100%] ' >
                                <div className=' flex justify-between '>
                                    <div className=''>{header}</div>
                                    <Plus size={16} className='text-gray-500' />
                                </div>
                            </CollapsibleTrigger>
                            <CollapsibleContent >
                                <div className='bg-gray-100 p-4 text-sm' >
                                    {parse(content)}
                                </div>
                            </CollapsibleContent>
                        </Collapsible>
                    })}
                    {/* </Collapsible> */}
                </div>
            </div>

        </div>
    )
}

export default FaqMain