import React from "react";
import { whyUsMainArr } from "./module";
import HtmlCodeRender from "src/shadcn/components/custom/HtmlCodeRender";
import { Separator } from "src/shadcn/components/ui";
import { BG_SKIN_COLOR_LIGHT, cn } from "src/helpers";

const WhyUs = () => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-3  ">
      {whyUsMainArr?.map(({ description, icon, title }, index) => (
        <div key={`${title}-${index}`} className={cn("flex justify-center relative  p-4 sm:p-6 ")}
        >
          {(index + 1) === 1 || (index + 1) === 2 ? (
            <>
              <div className={cn("absolute right-0 h-[70%]  md:h-[80%] border-b-2 md:border-r-2 border-dotted border-gray-500")}></div>
              <div className={cn("absolute bottom-0 w-[70%] md:w-[80%] border-b-2 md:border-b-2 border-dotted border-gray-500")}></div>
            </>
          ) : (index + 1) === 3 ? (
            <div className={cn("absolute bottom-0 w-[80%] border-b-2  border-dotted border-gray-500")}></div>
          ) : (index + 1) === 4 || (index + 1) === 5 ? (
            <>
            <div className="absolute right-0 h-[70%] md:h-[80%] md:border-r-2 border-dotted border-gray-500"></div>
            <div className="absolute bottom-0 w-[70%] md:w-0  border-b-2 border-dotted border-gray-500"></div>
            </>
          ) : <div className="absolute bottom-0 w-[70%] md:w-0  border-b-2 border-dotted border-gray-500"></div>}

          <div className="flex flex-col items-center space-y-6 py-6">
            <div className={cn(" bg-[#FFEDED] rounded-full flex justify-center items-center w-[74px] h-[74px] ")}>
              <img src={icon} alt={title} className={cn("w-[48px] h-[48px]  ")} />
            </div>
            <h1 className="text-[16px] font-bold text-center">{title}</h1>
            <p className="font-normal text-sm text-center">
              <HtmlCodeRender htmlString={description} />
            </p>
          </div>
        </div>
      ))
      }
    </div >
  );
};

export default WhyUs;
