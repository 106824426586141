import { useEffect, useState } from 'react'
import { catchAsync } from 'src/helpers'
import { columns } from './Table/components/columns'
import { DataTable } from './Table/components/data-table'

import { AxiosResponse } from 'axios'

type Props = {}


const Main = () => {


    const [tableData, settableData] = useState([])

    return <div>
        <DataTable data={tableData || []} columns={columns} />
    </div>
}

const CoupenTable = (props: Props) => {
    return (
        <div className='space-y-2'>
            <div className='text-primary uppercase font-semibold'>Coupens</div>
            <Main />
        </div>
    )
}

export default CoupenTable