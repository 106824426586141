import { useFormik } from "formik";
import { MoveUpRight } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { Button, Input } from "src/shadcn/components/ui";
import { INIT_SUBSCRIBE_FORM, SUBSCRIBE_FORM_FIELD, SUBSCRIBE_FORM_SCHEMA } from "./modules";
import { catchAsync, FormField, IS_SUCCESS_RESPONSE } from "src/helpers";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import { subscribeRequestApi } from "src/services/requests/Subscribe";
import { toast } from "sonner";

const Newsletter = () => {


  const [isLoading, setisLoading] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: INIT_SUBSCRIBE_FORM,
    validationSchema: SUBSCRIBE_FORM_SCHEMA,
    onSubmit(values, formikHelpers) {
      handleRequestSubscribe()
    },
  })

  const handleRequestSubscribe = catchAsync(async () => {
    setisLoading(true)
    return await subscribeRequestApi(formik.values)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      toast.success("Thank you for showing your interest, please check you inbox.")
      setisLoading(false)
    }
  }, () => { setisLoading(false) })


  return (
    <div className="w-full space-y-4">
      <div className="uppercase font-normal text-sm text-primary">
        Newsletter
      </div>
      <div className="w-full  text-white">
        For inquiries, please only contact us via the contact form. We
        look forward to receiving your message.
      </div>
      <form className="" onSubmit={formik.handleSubmit}>
        {
          SUBSCRIBE_FORM_FIELD.map((field: FormField) => {
            return <div><RenderFormComponents key={`new-enquiry-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} /></div>
          })
        }
        <div>
          <Button isLoading={isLoading} className="my-2 space-x-2 ">
            <span>
              <MoveUpRight className="text-white " />
            </span>
            <span>Subscribe</span>
          </Button>
        </div>
      </form>
    </div>
    // <div className="space-y-4 font-sans">
    //   <div className="uppercase font-bold text-sm text-primary">Newsletter</div>
    //   <div className="font-normal text-sm">
    //     For inquiries, please only contact us via the contact form. We look forward to receiving your message.
    //   </div>
    //   <form onSubmit={formik.handleSubmit}>
    //     {
    //       SUBSCRIBE_FORM_FIELD.map((field: FormField) => {
    //         return <div><RenderFormComponents key={`new-enquiry-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} /></div>
    //       })
    //     }
    //     <div>
    //       <Button isLoading={isLoading} className="space-x-2 rounded-full">
    //         <span>
    //           <MoveUpRight className="text-white " />
    //         </span>
    //         <span>Subscribe</span>
    //       </Button>
    //     </div>
    //   </form>
    // </div>
  );
};

export default Newsletter;
