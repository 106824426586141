import { AFFORDABLE_SOLUTIONS_IMAGE, CUSTOMER_SATISFACTION_IMAGE, END_TO_END_SUPPORT_IMAGE, EXTENSIVE_NETWORK_IMAGE, SUSTAINABILITY_FOCUS_IMAGE, TRUSTED_EXPERTS_IMAGE } from "src/helpers";

interface IWhyUsMainArr {
  icon: any;
  title: string;
  description: string;
}
const Trusted_Experts = TRUSTED_EXPERTS_IMAGE
const Affordable_Solutions = AFFORDABLE_SOLUTIONS_IMAGE
const End_to_End_Support = END_TO_END_SUPPORT_IMAGE
const Sustainability_Focus = SUSTAINABILITY_FOCUS_IMAGE
const Extensive_Network = EXTENSIVE_NETWORK_IMAGE
const Customer_Satisfaction = CUSTOMER_SATISFACTION_IMAGE

export const whyUsMainArr: IWhyUsMainArr[] = [
  {
    description:
      "With years of experience in</br> the solar industry, our team </br> consists of skilled </br> professionals who are </br> committed to delivering </br> high-quality solar solutions.",
    icon: Trusted_Experts,
    title: "Trusted Experts",
  },
  {
    description:
      "We connect you with the </br> best financial institutions </br> and partners, ensuring </br> cost-effective options for </br> all your solar needs.",
    icon: Affordable_Solutions,
    title: "Affordable Solutions",
  },
  {
    description:
      "From consultation to </br> installation and beyond, </br> we  provide hassle-free </br>service  at every step of </br> your  solar  journey. ",
    icon: End_to_End_Support,
    title: "End-to-End Support",
  },
  {
    description:
      "By choosing us, you </br> contribute to a cleaner,</br> greener future by reducing </br> dependence on non- </br>renewable energy sources. ",
    icon: Sustainability_Focus,
    title: "Sustainability Focus",
  },
  {
    description:
      "Our wide network of </br> developers, buyers, and </br>consultants ensures you get the best deals, whether</br> you are installing, selling, or </br>leasing solar projects. ",
    icon: Extensive_Network,
    title: "Extensive Network",
  },
  {
    description:
      "Our customer-first</br> approach guarantees that </br> your experience will be </br> smooth and beneficial, with </br> dedicated support to </br> address any concerns. ",
    icon: Customer_Satisfaction,
    title: "Customer Satisfaction",
  },
];
