import React from 'react'
import CoupenTable from 'src/modules/Coupen/CoupenHistory'
import ReferredRewardsTable from 'src/modules/ReferredRewards'

type Props = {}

const ReferredRewardsMain = (props: Props) => {
    return (
        <div>
            <div className='relative h-[90vh] '>
                <div className='h-[200px] bg-gray-100 rounded-b-3xl'></div>
                <div className='max-w-[80vw] mx-auto -mt-[100px] rounded-xl bg-white p-4 space-y-4'>
                    <ReferredRewardsTable />
                </div>
            </div>

        </div>
    )
}

export default ReferredRewardsMain