import { useState, useEffect } from 'react';
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers';

const useFetchData = (fetchFunction: () => Promise<any>) => {

  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    handleFunction()
  }, [fetchFunction]);

  const handleFunction = catchAsync(async () => {
    setIsLoading(true)
    return await fetchFunction()
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setData(result.data)
      setIsLoading(false)
    }
  }, () => { setIsLoading(false) })

  return { data, isLoading };
};

export default useFetchData;