import RoundedButton from "src/shadcn/components/custom/RoundedButton"
import { INITIAL_PAYMENT_DETAILS_VALUES, PAYMENT_DETAILS_FORM_FIELDS, paymentDetailsSchema } from "../Orders/modules"
import { catchAsync, cn, FormField, IS_SUCCESS_RESPONSE } from "src/helpers"
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents"
import { useFormik } from 'formik'
import { RiMastercardFill, RiVisaLine } from "react-icons/ri"
import { IoCardOutline } from "react-icons/io5"
import { useEffect, useMemo, useState } from "react"
import { checkCouponsStatusApiRequest, createOrderApiRequest, getAllCouponsApiRequest, getOrderdDetailsByOrderIdApiRequest, updateOrderStatusApiRequest } from "src/services/requests/Order"
import { useAuth } from "../Auth/Core"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { getTempDataRootState } from "src/redux/reducers/tempDataSlice"
import { useNavigate, useParams } from "react-router-dom"
import { MAIN_ROUTES_CONST } from "src/navigations/modules"
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog"
import { Button } from "src/shadcn/components/ui"


type Props = {}

const OrderPaymentForm = (props: Props) => {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [fetchingOrderDetails, setFetchingOrderDetails] = useState<boolean>(false)
    const { currentUser } = useAuth()
    const [showDialog, setshowDialog] = useState<boolean>(false)
    const navigate = useNavigate()
    const { selectedService } = useSelector((state: RootState) => getTempDataRootState(state))
    const { order_id } = useParams()
    const [orderDetails, setorderDetails] = useState<any>(undefined)
    const [coupons, setcoupons] = useState<any>(undefined)
    const [couponsDetails, setCouponsDetails] = useState<any>(undefined)
    const [selectedCoupon, setSelectedCoupon] = useState<any>(undefined)

    console.log({selectedService});
    

    useEffect(() => {
        if (order_id) handlefetchOrderZDetails()
        if (currentUser?.id) handlefetchAllCoupons()
        return () => { }
    }, [order_id, currentUser])


    const formik = useFormik({
        initialValues: INITIAL_PAYMENT_DETAILS_VALUES,
        validationSchema: paymentDetailsSchema,
        onSubmit(values, formikHelpers) {
            //  onSubmit(values)
            // handleCreateOrder()
            handleUpdateOrder()
        },
    })

    const handlefetchOrderZDetails = catchAsync(async () => {
        setFetchingOrderDetails(true)
        return order_id && await getOrderdDetailsByOrderIdApiRequest(order_id)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            console.log({ order: result });

            setorderDetails(result?.data[0])
            setFetchingOrderDetails(false)
        }
    }, () => { setFetchingOrderDetails(false) })



    const handlefetchAllCoupons = catchAsync(async () => {
        setFetchingOrderDetails(true)
        return currentUser && await getAllCouponsApiRequest(currentUser?.id)
    }, (result: any) => {
        ;
        if (IS_SUCCESS_RESPONSE(result)) {
            setcoupons(result?.data)
            setFetchingOrderDetails(false)
        }
    }, () => { setFetchingOrderDetails(false) })

    const handleUpdateOrder = catchAsync(async () => {
        setIsLoading(true)
        return await updateOrderStatusApiRequest(orderDetails?.transaction_id, {
            "user_id": currentUser?.id,
            "order_id": orderDetails?._id,
            "card_type": "debit",
            "card_number_masked": formik.values.card_number_masked,
            "bank_transaction_id": "XHBJHGBJJ32423424234",
            "total_card_payment": orderDetails?.total_price,
            "payment_status": "success",
            "coupon_id": selectedCoupon?._id
        })
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setIsLoading(false)
            setshowDialog(true)
        }
    }, () => { setIsLoading(false) })



    const handleCheckCouponStatus = catchAsync(async (data: any) => {
        setIsLoading(true)
        return await checkCouponsStatusApiRequest({
            "user_id": currentUser?.id,
            "service_id": orderDetails?.service_id,
            "coupon_id": data?._id
        })
    }, (result: any, data: any) => {
        console.log({ data });

        if (IS_SUCCESS_RESPONSE(result)) {
            setIsLoading(false)
            setCouponsDetails(result?.data)
            setSelectedCoupon(data?.args[0])
        }
    }, () => { setIsLoading(false) })


    console.log({ coupons, orderDetails });




    // const CardType = useMemo(() => {
    //     let cardNumber = formik.values.cardNumber
    //     if (cardNumber) {
    //         if (cardNumber.startsWith("4")) {
    //             return <RiVisaLine size={20} />
    //         }
    //         else if (cardNumber.startsWith("5") || cardNumber.startsWith("2")) {
    //             return <RiMastercardFill size={20} />
    //         }
    //         else {
    //             return <IoCardOutline size={20} />
    //         }
    //     }
    // }, [formik.values.cardNumber])

    return (
        <div className="w-full h-full  ">
            <div className="max-w-[40vw] mx-auto mt-[100px]">
                <div className="text-xl font-semibold uppercase text-primary text-center mb-4 " >Payment Details</div>
                <div className='p-4 border  rounded-xl relative w-full md:mb-10 xl:mb-0'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='grid grid-cols-1 gap-4'>
                            {
                                Object.keys(INITIAL_PAYMENT_DETAILS_VALUES).map((k: any) => {
                                    let formfield: any = PAYMENT_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                                    // if (formfield.dataKey == "cardNumber") {
                                    //     formfield = {
                                    //         ...formfield,
                                    //         componentProps: {
                                    //             ...formfield.componentProps,
                                    //             inputPrefixIcon: CardType
                                    //         }
                                    //     }
                                    // }
                                    return formfield && <RenderFormComponents key={`create-order-shipping-address-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield?.dataKey, e)} />
                                })
                            }
                        </div>
                        <div className="flex flex-col space-y-2 mt-2">
                            <div className="text-xs text-primary">Coupons</div>
                            <div className="flex flex-col space-y-2">
                                {
                                    Array.isArray(coupons) && coupons.length > 0 && coupons.map((d: any) => {
                                        return <div className={cn("p-2 border rounded-md border-primary flex justify-between items-center", { "bg-gray-50": selectedCoupon?._id == d?._id })}>
                                            <div className="font-semibold text-xl">{d?.couponcode}</div> {selectedCoupon?._id != d?._id ? <Button variant={'outlinePrimary'} type="button" size={'sm'} onClick={() => handleCheckCouponStatus(d)} > Apply</Button> : <Button type="button" variant={'outlineDestructive'} size={'sm'} onClick={() => setSelectedCoupon(undefined)} > Cancel</Button>}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className='flex justify-center py-4'>
                            <RoundedButton className="h-12">
                                <div className="flex justify-between items-center space-x-2">
                                    <div className="text-xl ">Total Payable</div>
                                    {couponsDetails?.isCouponValid && selectedCoupon ? <div className="flex-col p-2 ">
                                        <div className="font-bold text-xs line-through ">{orderDetails?.total_price}</div>
                                        <div className="font-bold text-xl ">{couponsDetails?.paybleAmount}</div>
                                    </div> : <div className="font-bold text-xl p-2 ">{orderDetails?.total_price}</div>}
                                </div>
                            </RoundedButton>
                        </div>

                    </form>
                </div>
            </div>
            <CustomeDialog isOpen={showDialog} headerTitle="Payment Confirmation." hideDefaultCancle onSubmit={() => {
                navigate("/")
            }} onCancle={() => setshowDialog(false)} >
                <p className="mb-4">Payment Done Successfully</p>

            </CustomeDialog>
        </div>
    )
}

export default OrderPaymentForm