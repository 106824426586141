import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Registration from 'src/modules/Auth/Registration'

type Props = {}

const RegistrationPage = (props: Props) => {

    return (
        <div className='flex justify-center items-center py-16 '>
            <div>
                <Registration  />
            </div>
        </div>
    )
}

export default RegistrationPage