import React from 'react'
import FinanceServiceDetails from 'src/modules/FinanceService/FinanceServiceDetails'

type Props = {}

const FinanceServiceMain = (props: Props) => {
  return (
    <FinanceServiceDetails />
  )
}

export default FinanceServiceMain