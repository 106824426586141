import React from 'react'
import EnquiryFormMain from 'src/modules/ReStructure/Welcome/EnquiryForm/EnquiryFormMain'

type Props = {}

const QuotationMain = (props: Props) => {
    return (
        <div className='py-16 flex justify-center items-center'>
            <div className=' flex flex-col space-y-4'>
                <div className='text-primary font-bold text-2xl text-center ' >Get Quotation Details</div>
                <EnquiryFormMain />
            </div>
        </div>
    )
}

export default QuotationMain