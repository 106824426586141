import { checkDecimal, ENERGY_SUN_IMAGE, SOLAR_POWER_GENERATION_IMAGE, SOLAR_POWER_GENERATION_PERCENTAGE_ICON, SOLAR_POWER_GENERATION_RUPEE_ICON, SOLAR_POWER_PLANT_CAPACITY_IMAGE } from "src/helpers";
import { Card, Separator } from "src/shadcn/components/ui";


const SolarPowerGenaration = ({ projectDetails }: { projectDetails: any }) => {

  const RuppeIcon = SOLAR_POWER_GENERATION_RUPEE_ICON
  const percentage = SOLAR_POWER_GENERATION_PERCENTAGE_ICON
  const solar_power_genration = SOLAR_POWER_GENERATION_IMAGE
  const solar_powerplant_capacity = SOLAR_POWER_PLANT_CAPACITY_IMAGE
  const energy_sun = ENERGY_SUN_IMAGE

  // const { quotationDetails } = useSelector((state:RootState) =>  getTempDataRootState(state))

  return (
    <div className="w-full grid grid-cols-4 gap-x-4 mt-12 font-sans">
      <Card className=" h-[303px] rounded-[50px] border border-black/50 flex flex-col items-center justify-center space-y-4">
        <div className="mr-8">
          <img
            src={solar_powerplant_capacity}
            alt="solar_powerplant_capacity"
          />
        </div>

        <div className="text-xl font-normal text-center">
          Solar Power Plant <br />
          Capacity
        </div>
        <div className="text-primary text-xl font-normal">
          50 <span className="text-[#216FC8]">kWh</span>
        </div>
      </Card>
      <Card className=" h-[303px] rounded-[50px] border border-black/50 px-4 space-y-4 flex flex-col justify-center items-center">
        <h1 className="text-xl font-bold text-center">
          Solar Power Generation
        </h1>
        <div className="bg-[#D9D9D966] w-full rounded-[25px] h-[70px] flex justify-around items-center">
          <img src={solar_power_genration} alt="solar_power_genration" />
          <div className="text-sm font-normal">
            <div>7000 kWh</div>
            <div>Energy Produced / Annum</div>
          </div>
        </div>

        <div className="bg-[#D9D9D966] w-full rounded-[25px] h-[70px] flex justify-around items-center">
          <img src={energy_sun} alt="energy_sun" />
          <div className="text-sm font-normal">
            <div>65909 kWp</div>
            <div>Excess Energy / Annum</div>
          </div>
        </div>
      </Card>
      <Card className="col-span-2 h-[303px] rounded-[50px] border border-black/50 flex flex-col justify-center items-center space-y-6">
        <h1 className="text-xl font-bold text-center">Annual Bill Saving</h1>
        <div className="w-full flex justify-center items-center h-[153px]">
          <div className="w-full flex flex-col justify-center items-center space-y-4">
            <img src={RuppeIcon} alt="RuppeIcon" />
            <div className="bg-[#D9D9D966] min-w-[200px] h-[70px] flex flex-col justify-center items-center rounded-[15px] p-2">
              <div className="text-[#216FC8] font-bold text-2xl"> ₹ {checkDecimal(parseFloat(projectDetails?.monthlyBillAmount).toString(), true)} <span className="text-sm">/ month</span></div>
              <div className="text-black text-[16px] font-normal">
                Annual Saving (INR)
              </div>
            </div>
          </div>
          <Separator
            orientation="vertical"
            className="border border-dashed border-[#0000004D]"
          />

          <div className="w-full flex flex-col justify-center items-center space-y-4">
            <img src={percentage} alt="percentage" />
            <div className="bg-[#D9D9D966] w-[200px] h-[70px] flex flex-col justify-center items-center rounded-[15px]">
              <div className="text-[#216FC8] font-bold text-2xl"> 1031 %</div>
              <div className="text-black text-[16px] font-normal">
                Annual Saving (%)
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SolarPowerGenaration;
