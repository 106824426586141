import React, { useState } from "react";
import EnquiryForm from "./Component/EnquiryForm";
import { PhoneCall } from "lucide-react";
import EnquiryFormTab from "./Component/EnquiryFormTab";

const EnquiryFormMain = () => {
  const [enquiryTab, setEnquiryTab] = useState('residential')
  return (
    <div className="w-full">

      <div className="w-full flex justify-center items-center">
        <div className="w-[80vw] ">
          {/* <EnquiryFormTab setEnquiryTab={setEnquiryTab} enquiryTab={enquiryTab} /> */}
          <div className=" bg-[#575451D9]  p-4">
            <h1 className="text-[16px] text-white font-bold font-sans px-2">
              Enquiry Now
            </h1>
            <EnquiryForm customerType={enquiryTab} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryFormMain;
