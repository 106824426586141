import React from 'react'
import Playbtn from 'src/shadcn/components/custom/Playbtn'
import { CarouselItem } from 'src/shadcn/components/ui'

type Props = {
    heading: string,
    number: string,
    description: string
}

const ServiceTile = ({ heading,number,description }: Props) => {
    return (
        <CarouselItem className=" w-full sm:basis-1/2 lg:basis-1/3 p-2 sm:p-4">
            <div className='relative border-2 border-primary rounded-xl p-4 hover:bg-theamblack hover:text-white h-full'>
                <div className='flex justify-between items-start py-2'>
                    <div className='uppercase font-bold '>{heading}</div>
                    <div className='text-4xl font-bold text-theamblack-foreground'>{number}</div>
                </div>
                <div className='py-4 border-t-2 text-sm '>
                    {description}
                </div>
                <div className='absolute -bottom-4 right-10 ' ><div><Playbtn /></div></div>
            </div>
        </CarouselItem>
    )
}

export default ServiceTile