import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";


export const columns: ColumnDef<Task>[] = [

  {
    accessorKey: 'userName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
  },
  {
    accessorKey: 'registrationDate',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Registration Date" />
    ),
  },
  {
    accessorKey: 'rewardAmount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Reward Amount" />
    ),
  },
  {
    accessorKey: 'rewardDate',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Reward Date" />
    ),
  },
 
  

];
