import Autoplay from "embla-carousel-autoplay";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCusrousal } from "src/modules/Welcome/useCusrousal";
import { getTempDataRootState, resetSetBannerIndex, setSelectedBannerIndex } from "src/redux/reducers/tempDataSlice";
import { RootState } from "src/redux/store";
import {
    Carousel,
    CarouselApi,
    CarouselContent
} from "src/shadcn/components/ui";
import ServiceTile from "./ServiceTile";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { cn, feadIn } from "src/helpers";
import { DATA } from "./modules";
import { motion, useInView, useScroll } from "framer-motion";



type Props = {}

const ResidentialServicesCarousal = (props: Props) => {
    const navigation = useNavigate();

    const [setApi, setSetApi] = useState<CarouselApi>();
    const [current, setCurrent] = React.useState(0);
    const dispatch = useDispatch()
    const { selectedIndex, scrollSnaps, onDotButtonClick } = useCusrousal(setApi);
    const plugin = React.useRef(
        Autoplay({ delay: 4000, stopOnInteraction: true })
    );


    const handleMouseEnter = () => {
        plugin.current.stop();
    };

    const handleMouseLeave = () => {
        plugin.current.play();
    };

    const [canScrollNext, setCanScrollNext] = useState(false);
    const [canScrollPrev, setCanScrollPrev] = useState(false);
  
    const updateScrollButtons = useCallback(() => {
      if (!setApi) return;
      setCanScrollNext(setApi.canScrollNext());
      setCanScrollPrev(setApi.canScrollPrev());
    }, [setApi]);

    const handleNext = () => {
        if (setApi) {
            setApi.scrollNext();
        }
    };


    const handlePrev = () => {
        if (setApi) {
            setApi.scrollPrev();
        }
    };

    useEffect(() => {
        if (!setApi) return;
        updateScrollButtons();
        setApi.on("select", updateScrollButtons);
        setApi.on("reInit", updateScrollButtons);
      }, [setApi, updateScrollButtons]);


    // useEffect(() => {
    //     if (!setApi) {
    //         return;
    //     }
    //     setCurrent(setApi.selectedScrollSnap() + 1);
    //     setApi.on("select", () => {
    //         setCurrent(setApi.selectedScrollSnap() + 1);
    //     });
    // }, [setApi]);

    


    return (
        <div className="w-full relative">
            <Carousel
                setApi={setSetApi}
                plugins={[plugin.current]}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="w-[80vw]  mx-auto"
             
            >
                {/* <motion.div initial="hidden" whileInView="show" viewport={{ amount: 0.5 }} variants={feadIn("up", 0.1)}  > */}
                    <CarouselContent
                        className=" mx-[20px] md:mx-[45px]  ">
                        {DATA.map((d: any) => {
                            return <ServiceTile {...d} />
                        })}
                    </CarouselContent>
                {/* </motion.div> */}
                {canScrollPrev && (<button onClick={handlePrev}>
                        <MdOutlineKeyboardArrowLeft size={64}
                            className={cn(
                                "hover:bg-white hover:text-primary hover:p-1 transition duration-300 ease-in-out rounded-full",
                                "absolute cursor-pointer rounded-full hover:text-primary",
                                "-left-8 md:left-3 top-[40%] -translate-y-[40%]"
                            )} />
                    </button>) }
                {canScrollNext && (<button onClick={handleNext}>
                        <MdOutlineKeyboardArrowRight size={64}
                            className={cn(
                                "hover:bg-white hover:text-primary hover:p-1 transition duration-300 ease-in-out rounded-full",
                                "absolute   cursor-pointer rounded-full hover:text-primary md:invisible",
                                "md:right-1 -right-8 top-[40%] -translate-y-[40%]"
                                
                            )} />
                    </button> )}
            </Carousel>
        </div>
    );
}

export default ResidentialServicesCarousal