import { useFormik } from 'formik'
import { useMemo } from 'react'
import { IoCardOutline } from "react-icons/io5"
import { RiMastercardFill, RiVisaLine } from "react-icons/ri"
import { FormField } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { INITIAL_PAYMENT_DETAILS_VALUES, PAYMENT_DETAILS_FORM_FIELDS, paymentDetailsSchema } from './modules'

type Props = {
    onSubmit: (e: any) => void
}

const PaymentForm = ({ onSubmit }: Props) => {
    const formik = useFormik({
        initialValues: INITIAL_PAYMENT_DETAILS_VALUES,
        validationSchema: paymentDetailsSchema,
        onSubmit(values, formikHelpers) {
            onSubmit(values)
        },
    })

    const CardType = useMemo(() => {
        let cardNumber = formik.values.card_number_masked
        if (cardNumber) {
            if (cardNumber.startsWith("4")) {
                return <RiVisaLine size={20} />
            }
            else if (cardNumber.startsWith("5") || cardNumber.startsWith("2")) {
                return <RiMastercardFill size={20} />
            }
            else {
                return <IoCardOutline size={20} />
            }
        }
    }, [formik.values.card_number_masked])

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='grid grid-cols-3 gap-4'>
                {
                    Object.keys(INITIAL_PAYMENT_DETAILS_VALUES).map((k: any) => {
                        let formfield: any = PAYMENT_DETAILS_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                        if (formfield.dataKey == "cardNumber") {
                            formfield = {
                                ...formfield,
                                componentProps: {
                                    ...formfield.componentProps,
                                    inputPrefixIcon: CardType
                                }
                            }
                        }
                        return formfield && <RenderFormComponents key={`create-order-shipping-address-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield?.dataKey, e)} />
                    })
                }
            </div>
            <div className='flex justify-center py-4'>
                <RoundedButton>Continue Payment</RoundedButton>
            </div>
        </form>

    )
}

export default PaymentForm