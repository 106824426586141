import { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API_URL from "src/services/consts/QuotationRequest"


export function createQuotationRequestRequestApi(data: any): Promise<any> {
    return axiosInstance.post(`${API_URL.CREATE_QUOTATION_REQUESTS}`, data).then((d: AxiosResponse) => d.data);
}

export function UpdateuotationRequestRequestApi(id: string, data: any): Promise<any> {
    return axiosInstance.put(`${API_URL.UPDATE_QUOTATION_REQUESTS}/${id}`, data).then((d: AxiosResponse) => d.data);
}
