import { BASE_URL, MOBILE_BASE_URL } from "../api_const"

export const ORDER_BASE_URL = BASE_URL + "/order"
export const URL_FETCH_ORDERS_BY_USER_ID = ORDER_BASE_URL + "/all"
export const URL_CREATE_ORDER=  ORDER_BASE_URL + "/create"
export const URL_ORDER_DETAILS=  ORDER_BASE_URL 
export const URL_CANCEL_ORDER  = ORDER_BASE_URL + "/cancel"
export const URL_ORDER_SUMMARY_BY_USER_ID  = ORDER_BASE_URL + "/summary"

export const URL_UPDATE_ORDER_STATUS  =BASE_URL  + "/transactions/update"
export const URL_COUPONS =BASE_URL  + "/coupans/details"

export const URL_COUPONS_VALIDATE =BASE_URL  + "/validate/coupon"
