import React, { useState } from 'react'
import { IMG_CROWDFUNDING_BANNER } from 'src/helpers'
import SolarPlan from '../ReStructure/CustomerJorney/Component/Solarplan/SolarPlan'
import ServicePlan from './ServicePlan'

type Props = {}

const FinanceServiceDetails = (props: Props) => {
    const [projectDetails, setProjectDetails] = useState<any>(undefined)

    return (
        <div>
        <div className='flex items-center w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_CROWDFUNDING_BANNER + ")", backgroundSize: "cover" }} >
            <div className='w-full  text-white text-xl md:text-3xl font-bold text-center' >Finance Service </div>
        </div>
        <div className='px-16'>
        <ServicePlan/>
        </div>
        </div>
    )
}

export default FinanceServiceDetails