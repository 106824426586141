import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { CELL_BACKGROUND_IMAGE, convertToCamelCase, IMG_FAQ_BANNER, toAbsoluteUrl } from 'src/helpers'
import ProjectLeftDetails from 'src/modules/Cell/ProjectLeftDetails'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { fetchProjectDetailsAsync, getProjectDetailsRootState, resetInvestment } from 'src/redux/reducers/projectDetailsSlice'
import { RootState } from 'src/redux/store'

type Props = {}

const CellLayout = (props: Props) => {

  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [path, setpath] = useState("")
  const { project_details } = useSelector((state: RootState) => getProjectDetailsRootState(state))

  useEffect(() => {
    if (id) {
      handleFetchProductDetails()
    }
    else navigate("/" + MAIN_ROUTES_CONST.marketplace)
    return () => {

      dispatch(resetInvestment())
    }
  }, [])

  const handleFetchProductDetails = async () => {
    id && dispatch(fetchProjectDetailsAsync(id))
  }


  useEffect(() => {
    let paths = pathname.split("/")
    setpath(convertToCamelCase(paths[2]) + " / " + paths[3])
    return () => { }
  }, [pathname])


  return (
    <div className='mb-24 lg:mb-24 md:mb-0'>
      <div className='w-full h-[150px] overflow-hidden' style={{ backgroundImage: "url(" + CELL_BACKGROUND_IMAGE + ")", backgroundSize: "cover" }} >
        <div className='flex flex-col justify-center mx-[50px] h-[100%]'>
          <div className='text-white text-3xl font-bold uppercase' >Sunrise Apartments Solar Project</div>
          <div className='text-white text-xs '>{path}</div>
        </div>
      </div>

      <div className=' w-full p-1 md:p-0 md:max-w-[80vw] mx-auto'>
        <div className='w-full md:flex '>
          <div className='w-full md:w-1/3 xl:w-2/6 md:sticky top-[3em] h-[500px] p-2 '>
            <ProjectLeftDetails />
          </div>
          <div className=' md:w-2/3 xl:w-4/6 w-full h-full p-2'>
            <Outlet />
          </div>
        </div>
      </div>
      {/* <div className='max-w-[80vw] mx-auto'> <Outlet /></div> */}
    </div>
  )
}

export default CellLayout