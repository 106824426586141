import { COMMERCIAL_SERVICE_BANNER_IMAGE } from 'src/helpers'
import EnquiryForm3 from 'src/modules/EnquiryForms/EnquiryForm3'
import { Button } from 'src/shadcn/components/ui'

const ServicesBanner = () => {
  const BannerImg = COMMERCIAL_SERVICE_BANNER_IMAGE

  return (
    <div className='relative w-full h-[686px] font-sans text-white '
      style={{
        background: `url(${BannerImg})`,
        backgroundSize: "cover",
        backgroundOrigin: "inherit",
        backgroundColor: "#000000C7"
      }}>
      {/* <div className='absolute inset-0 bg-gradient-to-r from-black/70 to-black/10'></div> */}
      <div className='w-full h-full flex justify-between items-center z-1'>
        <div className='w-[55%]  h-full flex flex-col justify-center items-center space-y-4'>
          <h1 className=' font-bold text-4xl text-center'>Innovative Solar Solutions</h1>
          <h3 className='font-normal text-center text-2xl'>Transforming the Future of Energy</h3>
          <div className='flex justify-center items-center'>
            <Button className='border border-white w-[177px] h-[42px] rounded-full'>Send Request</Button>
          </div>
        </div>
        <div className='w-[40%] h-full'>
          <div className="w-full bg-primary h-full font-sans">
            <div className="text-xl text-white py-10">
              <div className="font-normal  text-left px-10">
                Get Started on Your Solar<br /> Savings Journey
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="w-full px-10 h-[90%]">
                <div className="font-bold text-left text-xl py-4">Enquire Now!</div>
                <EnquiryForm3 />
              </div>
            </div>
          </div>
          {/* <SolarServicesEnquiryNow /> */}

        </div>
        <div className='w-[5%]'>
        </div>
      </div>
    </div>
  )
}

export default ServicesBanner