import { MAIN_ROUTES_CONST } from "src/navigations/modules";

export const OVERVIEW = { 
    title: "Project Rights Overview", 
    description: "Maximize the value of your solar project investments with our comprehensive project rights services. Whether you're a developer, investor, or landowner, securing project rights is essential for the successful execution of solar energy initiatives. Our team offers expertise in navigating the complexities of project rights, ensuring that your investments are protected and optimized.", 
    video: "", 
    img: "", 
    targetPage: MAIN_ROUTES_CONST.marketplace+"?projectCategoryType=Buy Project Rights"
  };
  
  export const KEY_FETURES =[ 
    { icon: "", title: "Comprehensive Legal Support", description: "Our legal experts assist in obtaining the necessary permits, licenses, and agreements required to secure project rights for solar installations, ensuring compliance with local regulations." }, 
    { icon: "", title: "Risk Mitigation", description: "We help identify and address potential risks associated with project rights, allowing you to navigate challenges proactively and avoid costly setbacks." }, 
    { icon: "", title: "Tailored Solutions", description: "Our services are customized to meet the unique needs of each project, ensuring that all aspects of project rights are handled efficiently and effectively." }, 
    { icon: "", title: "Long-Term Project Security", description: "By securing project rights, you ensure the long-term viability and protection of your solar investments, safeguarding against legal disputes or project delays." } 
  ];
 
  export const FAQ = [
    {
      "question": "What are project rights in solar projects?",
      "answer": "Project rights refer to legal permissions and ownership over aspects of a solar project, including land usage, permitting, development rights, and energy production."
    },
    {
      "question": "What types of project rights are typically required?",
      "answer": ""
    },
    {
      "question": "How are land rights secured for solar projects?",
      "answer": ""
    },
    {
      "question": "Can project rights be transferred or sold?",
      "answer": ""
    },
    {
      "question": "What permits are needed for a solar project?",
      "answer": ""
    },
    {
      "question": "How are project rights affected by regulatory changes?",
      "answer": ""
    }
  ]