import { MAIN_ROUTES_CONST } from "src/navigations/modules";

export const OVERVIEW = {
  title: "Sale of Existing Project",
  description: "Unlock the value of your solar investment by selling existing solar projects. Whether you're an investor, developer, or landowner looking to sell an operational solar installation, we provide a seamless, end-to-end service to help you maximize returns while ensuring a smooth transition for the buyer.",
  video: "https://example.com/video.mp4",
  img: "",
  targetPage: MAIN_ROUTES_CONST.marketplace + "?projectCategoryType=Buy Existing Projects"
};

export const KEY_FEATURES = [
  {
    icon: "",
    title: "Maximize Investment Returns",
    description: "We help you secure the best market value for your existing solar projects, ensuring a profitable sale that meets your financial objectives."
  },
  {
    icon: "Com 1441 x 445",
    title: "Com 1441 x 445 Market Analysis",
    description: "Our team conducts in-depth market research to provide accurate valuations and identify the right buyers for your solar project."
  },
  {
    icon: "",
    title: "Streamlined Transaction Process",
    description: "From initial negotiations to the final transfer of ownership, we handle every step of the sale process, ensuring a hassle-free experience."
  },
  {
    icon: "",
    title: "Legal and Regulatory Expertise",
    description: "We manage all legal and regulatory requirements, ensuring that the sale of your solar project complies with local laws and industry standards."
  }
];

export const FAQ = [
  {
    "question": "What does it mean to sell an existing solar project?",
    "answer": "Selling an existing solar project involves transferring ownership and operational rights of a developed solar installation to another party, such as an investor or energy company."
  },
  {
    "question": "What documents are needed for a sale?",
    "answer": ""
  },
  {
    "question": "How is the value of a solar project determined?",
    "answer": ""
  },
  {
    "question": "Are there tax implications for selling a solar project?",
    "answer": ""
  },
  {
    "question": "How long does the sales process typically take?",
    "answer": ""
  },
  {
    "question": "Can the new owner modify the solar system after purchase?",
    "answer": ""
  }
]