import { useFormik } from 'formik';
import React, { useState } from 'react'
import { INIT_P2P_ENERGY_ENQUIRY_FORM, P2P_ENERGY_ENQUIRY_FORM_FIELDS, P2P_ENERGY_ENQUIRY_FORM_VALIDATION_SCHEMA } from './modules';
import { catchAsync, FormField, IS_SUCCESS_RESPONSE } from 'src/helpers';
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents';
import { Button } from 'src/shadcn/components/ui';
import { MoveUpRight } from 'lucide-react';
import { createP2pEnergyRequestApiRequest } from 'src/services/requests/P2pEnergyForm';
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog';

type Props = {}

const P2pEnquiryForm = (props: Props) => {


    const [isLoading, setisLoading] = useState<boolean>(false)
    const [showDialog, setShowDialog] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: INIT_P2P_ENERGY_ENQUIRY_FORM,
        validationSchema: P2P_ENERGY_ENQUIRY_FORM_VALIDATION_SCHEMA,
        onSubmit(values, formikHelpers) {
            handleRequestSubscribe()

            
        },
    })

    console.log({values:formik.values,error: formik.errors});
    

    const handleRequestSubscribe = catchAsync(async () => {
        console.log("submiting");
        setisLoading(true)
        return await createP2pEnergyRequestApiRequest({
            ...formik.values,
            // consumerNumber:""
        })
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setShowDialog(true)
            formik.resetForm()
            setisLoading(false)
        }
    }, () => { setisLoading(false) })


    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className='flex flex-col space-y-4'>
                    {
                        P2P_ENERGY_ENQUIRY_FORM_FIELDS.map((field: FormField) => {
                            return <div><RenderFormComponents key={`new-p2p-enquiry-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} /></div>
                        })
                    }
                    <div className='pt-4 flex justify-center'>
                        <Button type='submit' isLoading={isLoading} >
                            <span>Submit</span>
                        </Button>
                    </div>
                </div>
            </form>
            <CustomeDialog headerTitle="Request Sent" isOpen={showDialog} hideDefaultSubmit onCancle={() => { setShowDialog(false) }} >
                <p className='mb-4'>Thank you for showing your interest, our team will be reach to you soon.</p>
            </CustomeDialog>
        </div>
    );
};

export default P2pEnquiryForm