import React from 'react'
import { checkDecimal } from 'src/helpers'
import { Card, CardContent, CardHeader, CardTitle, Skeleton } from 'src/shadcn/components/ui'

type Props = {
    title: string,
    value: string,
    icon?: any,
    description?: string,
    dataKey: any,
    prefix: string,
    isLoading?: boolean
}

const AnalysisTiles = ({ title, value, icon, description, dataKey, prefix, isLoading }: Props) => {
    return (

        <Card className='shadow-none  w-[350px] md:w-auto'>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium text-gray-500">
                    {title}
                </CardTitle>
                <div>{icon}</div>
            </CardHeader>
            <CardContent>
                {
                    !isLoading ? <>
                        <div className="text-2xl space-x-2 "><span className='text-gray-400'>{prefix}</span> <span className='font-bold'>{checkDecimal(value, true)}</span></div>
                        <p className="text-xs text-muted-foreground">
                            {description}
                        </p>
                    </> : <Skeleton className='w-[50%] h-4' />
                }
            </CardContent>
        </Card>

    )
}

export default AnalysisTiles