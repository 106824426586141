import { Card } from "src/shadcn/components/ui";

const ServicePlan = () => {

  return (
    <div className="font-sans mt-2">
      <div className="text-[#000000B2] font-bold text-[20px]">
        Select the Service Plan.
      </div>
      <div className="text-[16px] font-normal text-[#191D2380]">
        Read all plans or contact us we will guide you better.
      </div>

      <div className=" flex justify-center w-full mt-8 gap-12">
      <Card className=" font-sans bg-[#E0995833] border border-t-[#BF875426] border-t-[4px] rounded-[26px] w-[413px] h-[458px] drop-shadow-lg p-9 space-y-4">
        <h1 className="text-2xl font-bold text-primary text-center uppercase">ECOFY</h1>
        <div className="text-[#231D4F] flex justify-center items-center text-xl space-x-3 ">
          <div className="font-normal text-nowrap">Rate Of Interest :</div>
          <div className="font-bold text-nowrap">7.99% to 9.99%</div> 
        </div>

        <div className="text-[#231D4F] flex justify-center items-center text-xl space-x-3 ">
          <div className="font-normal text-nowrap">Repayment Term :</div>
          <div className="font-bold text-nowrap">5 to 30 Years</div> 
        </div>

        <div className="text-[#231D4F] flex justify-center items-center text-xl space-x-3 ">
          <div className="font-normal text-nowrap">Type of Credit :</div>
          <div className="font-bold text-nowrap">Installment credit</div> 
        </div>

        <div className="text-[#231D4F] flex justify-center items-center text-xl space-x-3 ">
          <div className="font-normal text-nowrap">Closing Cost :</div>
          <div className="font-bold text-nowrap">2% - 5%</div> 
        </div>

      </Card>

      <Card className=" font-sans bg-[#E0995833] border border-t-[#BF875426] border-t-[4px] rounded-[26px] w-[413px] h-[458px] drop-shadow-lg p-9 space-y-4">
        <h1 className="text-2xl font-bold text-primary text-center uppercase">CREDIT FAIR</h1>
        <div className="text-[#231D4F] flex justify-center items-center text-xl space-x-3 ">
          <div className="font-normal text-nowrap">Rate Of Interest :</div>
          <div className="font-bold text-nowrap">
          8.5% to 9.99% 
          </div>
        </div>

        <div className="text-[#231D4F] flex justify-center items-center text-xl space-x-3 ">
          <div className="font-normal text-nowrap">Repayment Term :</div>
          <div className="font-bold text-nowrap">10 to 20 Years</div> 
        </div>

        <div className="text-[#231D4F] flex justify-center items-center text-xl space-x-3 ">
          <div className="font-normal text-nowrap">Type of Credit :</div>
          <div className="font-bold text-nowrap">Revolving credit</div> 
        </div>

        <div className="text-[#231D4F] flex justify-center items-center text-xl space-x-3 ">
          <div className="font-normal text-nowrap">Closing Cost :</div>
          <div className="font-bold text-nowrap">Vary, often lower</div> 
        </div>
      </Card>
      </div>
    </div>
  );
};

export default ServicePlan;
