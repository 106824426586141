import OrderPaymentForm from 'src/modules/OrderConfirmation/OrderPaymentForm'

type Props = {}

const PaymentScreenPage = (props: Props) => {

 
    return (
        <div>
            <OrderPaymentForm />
        </div>
    )
}

export default PaymentScreenPage