import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ENQUIRY_FORM_2_FIELDS, enquiryForm2Schema, IEnquiryForm2, initialEnquiryForm2 } from './modules'
import { catchAsync, successTheme } from 'src/helpers'
import { createExistingProjectEnquiryRequestRequestApi } from 'src/services/requests/Projects'
import { AxiosResponse } from 'axios'
import { toast } from 'sonner'
import { ENQUIRY_FORM_1_FIELDS } from '../EnquiryForm1/modules'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const EnquiryForm2 = (props: Props) => {
    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        if (!id) {
            navigate("/")
        }
        return () => { }
    }, [id])


    const formik = useFormik<IEnquiryForm2>({
        initialValues: initialEnquiryForm2,
        validationSchema: enquiryForm2Schema,
        onSubmit: (values, { setSubmitting }) => {
            // Handle form submission here
            handleEnquiryForm()
        },
    });


    const handleEnquiryForm = catchAsync(
        async () => {
            setisLoading(true)
            return await createExistingProjectEnquiryRequestRequestApi({ project_id:id, ...formik.values});
        },
        (result: AxiosResponse) => {
            if (result.status == 201) {
                toast.success("Request sent successful", { style: successTheme })
                navigate(-1)
            }
            setisLoading(false)

        },
        () => {
            setisLoading(false)
        }
    );

    console.log({ error: formik.errors, values: formik.values })

    return (

        <div className='space-y-2 py-4'>
            <form className='space-y-2' onSubmit={formik.handleSubmit}>
                <div className='grid md:grid-cols-3 gap-4 px-4'>
                    {
                        ENQUIRY_FORM_2_FIELDS.map((field: any) => {
                            return <RenderFormComponents key={`existing-enquiry-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                        })
                    }
                </div>
                <div className='flex justify-center space-x-2'>
                    <RoundedButton isLoading={isLoading} onClick={() => navigate(-1)} variant={'outline'} type='button' className='w-[150px]'>Cancel</RoundedButton>
                    <RoundedButton isLoading={isLoading} type='submit' className='w-[150px]'>Submit</RoundedButton>
                </div>
            </form>
        </div>

    )
}

export default EnquiryForm2