import React, { useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { USER_ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import EnquiryForm2 from 'src/modules/EnquiryForms/EnquiryForm2'

type Props = {}

const ExistingEnquiryForm = (props: Props) => {

    const { currentUser } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!currentUser) {
            navigate("/")
        }
        return () => { }
    }, [currentUser])

    return (
        <div className='flex flex-col space-y-2 md:py-4 p-4 mb-40'>
            <div className='text-xl text-primary uppercase font-semibold'>Enquiry Request</div>
            <EnquiryForm2 />
        </div>
    )
}

export default ExistingEnquiryForm