import axios, { AxiosError } from 'axios'
import { type ClassValue, clsx } from "clsx"
import qs from 'qs'
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { toast } from 'src/shadcn/components/ui/use-toast';
import { twMerge } from "tailwind-merge"
import { ID, QueryResponseContextProps, QueryState } from './models'
import { uploadFileToServer } from 'src/services/requests/Common';
import { OpacityIcon } from '@radix-ui/react-icons';

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  // console.log({ isNotEmpty: obj });
  if (typeof obj == "string" || typeof obj == "number") {
    return obj !== undefined && obj !== null && obj !== ''
  }
  else if (Array.isArray(obj)) {
    return obj.length > 0
  }
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {


  const pagination = qs.stringify(state, { filter: ['page', 'items_per_page'], skipNulls: true })
  const sort = qs.stringify(state, { filter: ['sort', 'order'], skipNulls: true })
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`
      })
      .join('&')
    : '';

  const _fkey = qs.stringify(state, { filter: ['_fkey'], skipNulls: true })
  const _level = qs.stringify(state, { filter: ['_level'], skipNulls: true })
  const name = qs.stringify(state, { filter: ['name'], skipNulls: true })

  const _f = state.f && Object.keys(state.f).length > 0
    ? Object.entries(state.f as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}:${obj[1]}`
      })
      .join(';')
    : '';

  // console.log(_f);


  const final_f = state.f && _f && Object.values(state.f).every((o: any) => (o !== undefined)) ? `f=${_f}` : ''

  // console.log(final_f);
  // console.log({state});

  const _q = state.q && Object.keys(state?.q).length > 0
    ? Object.entries(state.q as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {

        return `${obj[0]}:${obj[1]}`
      })
      .join(';')
    : '';

  const final_q = state.q && Object.keys(state?.q).length > 0 && Object.values(state.q).every((o: any) => (o !== undefined)) ? `q=${_q}` : ''

  return [pagination, sort, search, filter, name, _fkey, _level, final_f, final_q]
    .filter((f) => f !== '')
    .join('&')
  //.toLowerCase()
}

function stringifyObjectToRequestQuery(object: any): string {
  // console.log({ object });

  const filter = object
    ? Object.entries(object as Object)
      .filter((obj) => {
        // console.log({ obj });
        return isNotEmpty(obj[1])
      })
      .map((obj) => {
        // console.log({ map: obj });
        return `${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  return [filter]
    .filter((f) => f)
    .join('&')
  // .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID }>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


const startCase = (string: any) => {
  return string?.replace(/[\W_]+/g, ' ').replace(/(?<=[a-z])([A-Z])/g, ' $1').replace(/^[a-z]/, (match: any) => match.toUpperCase());
};

const camelCase = (string: any) => {
  return string?.replace(/[\W_]+(.|$)/g, (_: any, chr: any) => ' ' + chr.toUpperCase()).trim();
};


const convertToNumber = (string: any) => {
  return string?.replace(/[^0-9]+/g, "");
};


const isNumeric = (num: any) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num as number);

function convertToCamelCase(str: any) {
  return startCase(camelCase(str))
}

function convertToCamelCaseNormal(str: any) {
  return startCase(camelCase(str))
}

const catchAsync = (asyncFunction: any, onSuccess: any, onError?: any, returnParamsOnSuccess?: any) => {
  return async (...args: any[]) => {
    try {
      const result = await asyncFunction(...args);
      if (onSuccess) {
        onSuccess(result, { args, returnParamsOnSuccess });
      }
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        if (error) {
          const status = error.response?.status;
          error.response?.data?.message && toast({
            variant: "destructive",
            title: error.response?.data?.message || "Unknown Error",
            description: "",
          })
          onError && onError(error.response?.data?.message)
          // }
        }
      } else {
        // toast({
        //   variant: "destructive",
        //   title: "Unexpected error",
        //   description: error?.message,
        // })
        onError && onError(error?.message,)
      }
    }
  };
};

const parseFiltersDataIntoQuery = (filterObj: any) => Object.entries(filterObj).reduce((acc: any, [key, value]: [any, any]) => {
  if (Array.isArray(value)) {
    if (value.every((d) => d !== 0)) {
      acc[key] = value.join(",");
    }
  } else if (typeof value === 'object' && value !== null) {
    if (value.min && value.min !== 0 && value.max && value.max !== 0) {
      acc[key] = `${value.min}:${value.max}`;
    }
  }
  else if (value && typeof value == "string" || typeof value == "number") {
    acc[key] = value
  }
  return acc;
}, {});


// const checkAnyFileToBeUpload = async (obj: any): Promise<any> => {
//   // console.log({ beforeUpload: obj });

//   const uploadedFiles: any = {};
//   for (const [key, value] of Object.entries(obj)) {
//     // console.log("checking :", { key, value, type: typeof value });
//     if (Array.isArray(value)) {
//       const filesToUpload = value.filter((item) => item instanceof File);
//       const filesPrviouslyUploaded = value.filter((item) => typeof item === "string");
//       // console.log({ key, filesToUpload });
//       let newFiles: any = []
//       if (filesToUpload.length > 0) {
//         newFiles = await Promise.all(
//           filesToUpload.map((file) => uploadFileToServer(file))
//         );
//       }
//       uploadedFiles[key] = [...newFiles, ...filesPrviouslyUploaded]
//     } else if (typeof value === 'object') {
//       const val: any = value
//       uploadedFiles[key] = val?.name && await uploadFileToServer(val);
//     } else if (value instanceof File) {
//       // console.log({ key, value });

//       uploadedFiles[key] = await uploadFileToServer(value);
//     } else {
//       uploadedFiles[key] = value;
//     }
//   }
//   return uploadedFiles;
// };;


const checkAnyFileToBeUpload = async (obj: any): Promise<any> => {
  console.log({ beforeUpload: obj });
  const uploadedFiles: any = {};
  try {
    for (const [key, value] of Object.entries(obj)) {
      console.log("checking :", { key, value, type: typeof value });
      if (Array.isArray(value)) {
        if (value.every((d: any) => typeof d == "object" && !(d instanceof File))) {
          const prepareNewArray = await Promise.all(
            value.map(async (d: any) => {
              const result = await checkAnyFileToBeUpload(d);
              return result[0];
            })
          );
          uploadedFiles[key] = prepareNewArray
        }
        else {
          const filesToUpload = value.filter((item) => item instanceof File);
          const filesPrviouslyUploaded = value.filter((item) => typeof item === "string");
          console.log({ key, filesToUpload });
          let newFiles: any = []
          if (filesToUpload.length > 0) {
            newFiles = await Promise.all(
              filesToUpload.map((file) => uploadFileToServer(file))
            );
          }
          uploadedFiles[key] = [...newFiles, ...filesPrviouslyUploaded]
        }
      } else if (typeof value === 'object') {
        const val: any = value
        uploadedFiles[key] = val?.name && await uploadFileToServer(val);
      } else if (value instanceof File) {
        console.log({ key, value });
        uploadedFiles[key] = await uploadFileToServer(value);
      } else {
        uploadedFiles[key] = value;
      }
    }
    return [uploadedFiles || obj, true];
  } catch (error) {
    let err = error as Error | AxiosError
    if (axios.isAxiosError(err)) {
      if (err) {
        const status = err.response?.status;
        toast({
          variant: "destructive",
          title: "File upload failed",
          description: err.response?.data?.message,
        })
        // }
      }
    } else {
      toast({
        variant: "destructive",
        title: "Unexpected error",
        description: err?.message,
      })
    }
    return [obj, false]
  }
};


function formatIndianNumber(input: string | null | undefined, decimalPlaces: number = 2): string {
  if (input === null || input === undefined) {
    return 'null';
  }

  if (typeof input === 'number') {
    return Number(input).toLocaleString('en-IN', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  }

  const regex = /\d+(?:\.\d+)?/g;
  const matches = input.match(regex);
  if (!matches) {
    return input;
  }

  let result = input;
  for (const match of matches) {
    const num = parseFloat(match);
    const formattedNum = num.toLocaleString('en-IN', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
    result = result.replace(match, formattedNum);
  }

  return result;
}

function checkDecimal(str: string, formatted = false, minify = false) {
  let result: any = ""

  if (/\d/.test(str) && str.toString().includes('.')) {
    // console.log({ str });
    if (str) {
      const [intPart, decPart] = str.toString().split('.');
      if (parseFloat(decPart) > 0) {
        result = formatted ? formatIndianNumber(str, 2) : str;
      } else {
        result = formatted ? formatIndianNumber(parseInt(intPart).toString(), 0) : parseInt(intPart);
      }
    }
    else result = formatted ? formatIndianNumber(parseInt(str).toString(), 0) : parseInt(str);
  } else {
    result = formatted ? formatIndianNumber(parseInt(str).toString(), 0) : parseInt(str);
  }
  return result
}


const convertArrayToOptionType = (data: string[]) => data.map((d: string) => ({ label: d, value: d }))


const feadIn = (direction: "up" | "left" | "down" | "right", delay: any, distance = 100) => {
  return {
    hidden: {
      y: direction == "up" ? distance : direction === "down" ? -distance : 0,
      x: direction == "left" ? distance : direction === "right" ? -distance : 0,
      opacity: 0
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: "tween",
        duration: 1.2,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75]
      }
    }
  }
}


export {
  calculatedGroupingIsDisabled, parseFiltersDataIntoQuery,
  calculateIsAllDataSelected, camelCase, catchAsync, cn, convertToCamelCase, convertToCamelCaseNormal, createResponseContext, groupingOnSelect,
  groupingOnSelectAll, isNotEmpty, isNumeric, parseRequestQuery, startCase, stringifyObjectToRequestQuery, stringifyRequestQuery, useDebounce, convertToNumber, checkAnyFileToBeUpload,
  formatIndianNumber, checkDecimal, convertArrayToOptionType, feadIn
}

