import { Button } from 'src/shadcn/components/ui/button'
import { cn, HERO_IMG_1, ICON_ENERGY_SUN, RESIDENTIAL_BANNER, RESPONSIVE_BANNER_1 } from 'src/helpers'
import { Carousel, CarouselApi, CarouselContent, CarouselItem, } from 'src/shadcn/components/ui/carousel'
import { ReactSVG } from 'react-svg'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { useNavigate } from 'react-router-dom'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { useState } from 'react'
import { useCusrousal } from './useCusrousal'

type Props = {}


const HeroSection = (props: Props) => {
    const navigation = useNavigate()

    const [setApi, setSetApi] = useState<CarouselApi>()

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useCusrousal(setApi)

    // console.log({ setApi, selectedIndex, scrollSnaps, });



    return (
        <div>
            <Carousel setApi={setSetApi} className="">
                <CarouselContent>
                    <CarouselItem >
                        <div
                            className={cn(`w-full h-[80vh] `)}
                            style={{
                                background: `url(${RESIDENTIAL_BANNER})`,
                                backgroundSize: "cover",
                                backgroundPosition:"center"
                            }}
                        >
                            <div className=" w-[500px] space-y-3 ml-20">
                                <div className='pt-[20vh]'>
                                    <h1 className="font-sans font-bold text-3xl">Harness the Power of the Sun, Lower Your Bills!</h1>
                                    <p className="text-[16px] font-sans font-normal">
                                        Take control of your energy consumption and save on electricity bills every month
                                    </p>
                                </div>
                            </div>
                        </div>
                    </CarouselItem>
                    {/* <CarouselItem >
                        <div className={cn(`w-100 h-[584px] flex flex-col justify-center items-center space-y-[32px]`)} style={{ background: `url(${HERO_IMG_1})` }} >
                            <div className='text-white text-5xl text-center'>Solar Marketplace
                                <br />to Solve your all Project Needs</div>
                            <div className='text-white text-center max-w-[900px]'>The Electricity Marketplace offers a wide variety of features that make it an ideal place to buy and sell renewable energy.</div>
                            <div><RoundedButton className='space-x-2' onClick={() => navigation("/" + MAIN_ROUTES_CONST.p2pEnergyTrading)}> <span> <ReactSVG src={ICON_ENERGY_SUN} /></span> <span>Learn More</span></RoundedButton></div>
                        </div>
                    </CarouselItem>
                    <CarouselItem >
                        <div className={cn(`w-100 h-[584px] flex flex-col justify-center items-center space-y-[32px]`)} style={{ background: `url(${HERO_IMG_1})` }} >
                            <div className='text-white text-5xl text-center'>Virtual Solar Marketplace
                                <br />Now, Buy your own Green Energy through</div>
                            <div className='text-white text-center max-w-[900px]'>Want to buy green Energy, but do not have space or cannot own the Solar plant, out Blockchain technology enabled Virtual Solar Plants (VSP).</div>
                            <div><RoundedButton className='space-x-2' onClick={() => navigation("/" + MAIN_ROUTES_CONST.p2pEnergyTrading)}> <span> <ReactSVG src={ICON_ENERGY_SUN} /></span> <span>Learn More</span></RoundedButton></div>
                        </div>
                    </CarouselItem> */}
                </CarouselContent>
            </Carousel>

            <div className='absolute bottom-16 left-0 right-0 w-100 flex justify-center space-x-2 '>
                {
                    scrollSnaps.map((span: any, indx: number) => {
                        return <button className={cn('h-1 w-10 bg-white rounded-full', { "bg-primary": selectedIndex == indx })}></button>
                    })
                }
            </div>

        </div>
    )
}

export default HeroSection