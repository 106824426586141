import React from 'react'
import { Button, Popover, PopoverContent, PopoverTrigger } from '../ui'
import Slider from 'rc-slider'
import { ClassNameValue } from 'tailwind-merge'
import { checkDecimal, cn } from 'src/helpers'
import millify from "millify";

type Props = {
    name: string, min: number, max: number, steps?: number, onChange: (e: any) => void, triggerClassName?: ClassNameValue, value: number[]
}
const RangeSelector = ({ name, min, max, steps = 1, onChange, triggerClassName, value }: Props) => {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <div className={cn("cursor-pointer text-md", triggerClassName)}>{value[0] > 0 || value[1] > 1 ? millify(value[0] || 0) + "-" + millify(value[1] || 0) : name}</div>
            </PopoverTrigger>
            <PopoverContent className="p-4">
                <div className="">
                    <div className="flex justify-between items-center">
                        <span className="text-gray-500 text-sm">{millify(value[0] || 0)}</span>
                        <span className="text-gray-500 text-sm">{millify(value[1] || 0)}</span>
                    </div>
                    <div className='mx-1 '>
                        <Slider
                            range
                            min={min}
                            max={max}
                            value={value}
                            step={steps}
                            onChange={(value: any) => {
                                onChange(value)
                            }}
                            trackStyle={[{ backgroundColor: "hsl(var(--primary))", height: 3 }]}
                            handleStyle={[
                                { backgroundColor: "hsl(var(--primary-foreground))", borderColor: "hsl(var(--primary))" },
                                { backgroundColor: "hsl(var(--primary-foreground))", borderColor: "hsl(var(--primary))" },
                            ]}
                            railStyle={{ backgroundColor: "#E5E7EB", height: 6 }}
                        />
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-gray-500">{min}</span>
                        <span className="text-gray-500">{max}</span>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}

export default RangeSelector