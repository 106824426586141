import { AVAILABLE_SPACE_UNITS_OPTIONS, DISCOMS_OPTIONS, FormField, NUMBER_REGEX, TYPE_OF_SPACE_OPTIONS } from "src/helpers";
import * as Yup from 'yup';
export interface IEnquiryForm {
    customerType?: string,
    typeOfSpace: string,
    pincode: string,
    phoneNumber: string,
    discom: string,
    monthlyBillAmount: string,
    area: string,
    lat: string,
    long: string

}
export const ENQUIRY_FORM_FIELDS: FormField[] = [

    {
        label: 'Pincode',
        dataKey: 'pincode',
        componentType: 'input',
        componentProps: {
            type: 'text',
            required: true,
            regex: NUMBER_REGEX,
            labelStyle:"text-white",
            error_className:"text-white mt-2 font-semibold",
            placeholder: 'Pincode *',
            maxLength: 10
        },
    },
    {
        label: 'Discom',
        dataKey: 'discom',
        componentType: 'options',
        componentProps: {
            required: true,
            labelStyle:"text-white",
            error_className:"text-white mt-2 font-semibold",
            options: DISCOMS_OPTIONS
        },
    },
    {
        label: 'Monthly Bill Amount',
        dataKey: 'monthlyBillAmount',
        componentType: 'input',
        componentProps: {
            type: 'text',
            required: true,
            regex: NUMBER_REGEX,
            labelStyle:"text-white",
            error_className:"text-white mt-2 font-semibold",
            placeholder: 'Monthly bill',
            maxLength: 10
        },
    },
    {
        label: 'Area',
        dataKey: 'area',
        componentType: 'input',
        componentProps: {
            type: 'number',
            required: true,
            numberFormatted: true,
            labelStyle:"text-white",
            error_className:"text-white mt-2 font-semibold",
            placeholder: 'Area Sqft',
            inputSuffixIcon:"/SqFt"
        },
    },
    {
        label: 'Type oF Space',
        dataKey: 'typeOfSpace',
        componentType: 'options',
        componentProps: {
            options: TYPE_OF_SPACE_OPTIONS,
            labelStyle:"text-white",
            error_className:"text-white mt-2 font-semibold",
            required: true
        },
    },
    {
        label: 'Phone Number',
        dataKey: 'phoneNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            required: true,
            regex: NUMBER_REGEX,
            labelStyle:"text-white",
            error_className:"text-white mt-2",
            placeholder: 'Enter your phone number',
            maxLength: 10
        },
    },

];

export const ENQUIRY_INIT: IEnquiryForm = {
    // customerType: "",
    pincode: '',
    phoneNumber: '',
    discom: '',
    monthlyBillAmount: '',
    area: '',
    typeOfSpace: "",
    lat: "",
    long: "",

};

export const EnquiryFormSchema = Yup.object().shape({
    customerType:Yup.string().required('Select Customer TYpe is required'),
    phoneNumber: Yup.string().length(10,"Must be 10 digits").required('Phone number is required'),
    pincode: Yup.string().length(6,"Must be 6 digits").required('Pincode is required'),
    discom: Yup.string().required('Discom is required'),
    monthlyBillAmount: Yup.string().required('Monthly Bill is required'),
    area: Yup.string().required('Area is required'),
    typeOfSpace:Yup.string().required('Type of space is required'),
});